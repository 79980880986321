import React from "react";
import { Button, Modal } from "react-bootstrap";
import * as Icon from "react-feather";

interface SessionExpiredModalProps {
  showExpirationModal: boolean;
  onClickRelogin(): void;
}

export const SessionExpiredModal: React.FC<SessionExpiredModalProps> = ({
  showExpirationModal,
  onClickRelogin,
}) => {
  return (
    <Modal show={showExpirationModal} onHide={onClickRelogin}>
      <Modal.Body className="text-center m-3">
        <Icon.AlertTriangle size="90" color="rgb(217, 83, 79)" />
        <h3 className="mt-5">Whoops, your session has expired</h3>
        <p className="mt-2">Please relogin to keep using AdvizorStack</p>
        <div className="mt-5">
          <Button
            onClickCapture={onClickRelogin}
            variant="primary"
            onClick={onClickRelogin}
          >
            Relogin
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
