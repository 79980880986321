import { useAuth0 } from "@auth0/auth0-react";
import React, { ComponentType, useEffect, useState } from "react";
import { PageLoader } from "./page-loader";
import { SessionExpiredModal } from "./SessionExpiredModal";
import { isValidUser } from "../../models/user";
import { InvalidUserCard } from "./InvalidUserCard";

interface ProtectedRouteProps {
  component: ComponentType;
}

const RedirectContent = () => {
  return (
    <div className="page-layout">
      <PageLoader />
    </div>
  );
};

const defaultReturnTo = (): string =>
  `${window.location.pathname}${window.location.search}`;

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
}) => {
  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getIdTokenClaims,
  } = useAuth0();

  const routeIsAuthenticated = isAuthenticated;

  useEffect(() => {
    if (isLoading || routeIsAuthenticated) {
      return;
    }
    const opts = {
      appState: {
        returnTo: defaultReturnTo(),
      },
    };
    (async (): Promise<void> => {
      await loginWithRedirect(opts);
    })();
  }, [isLoading, routeIsAuthenticated, loginWithRedirect]);

  const [showExpirationModal, setShowExpirationModal] = useState(false);

  useEffect(() => {
    if (isLoading || !routeIsAuthenticated) {
      return;
    }

    // taken from: https://community.auth0.com/t/check-session-without-extending-it/58569/5
    const check = () => {
      getIdTokenClaims()
        .then((claims) => {
          const expired = claims?.exp ? claims?.exp * 1000 < Date.now() : true;
          if (expired) {
            setShowExpirationModal(true);
          }
        })
        .catch((e) => {
          if (e.error === "login_required") {
            setShowExpirationModal(true);
          }
        });
    };

    const CHECK_INTERVAL = 30_000;
    const internalHandle = setInterval(() => {
      check();
    }, CHECK_INTERVAL);

    return () => {
      clearInterval(internalHandle);
    };
  }, [isLoading, routeIsAuthenticated, getIdTokenClaims]);

  if (!routeIsAuthenticated) {
    return <RedirectContent />;
  }

  if (user && !isValidUser(user)) {
    return <InvalidUserCard onClickRelogin={loginWithRedirect} />;
  }

  return (
    <>
      <Component />
      <SessionExpiredModal
        showExpirationModal={showExpirationModal}
        onClickRelogin={loginWithRedirect}
      />
    </>
  );
};
