import React from "react";
import { Card } from "react-bootstrap";
import Iframe from "react-iframe";
import "./Calendar.scss";
import { useAuth0 } from "@auth0/auth0-react";

const Calendar = () => {
  const { getIdTokenClaims } = useAuth0();
  const [calendarUrl, setCalendarUrl] = React.useState<string | null>(null);
  React.useEffect(() => {
    (async () => {
      const claims = await getIdTokenClaims();
      if (claims && claims["calendar_url"]) {
        setCalendarUrl(claims["calendar_url"]);
      }
    })();
    // eslint-disable-next-line
  }, []);

  if (!calendarUrl) {
    return null;
  }

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title className="mb-0">Calendar</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        <div className="align-self-center w-100 h-100">
          <React.Fragment>
            <Iframe
              url={calendarUrl}
              id="calendarFrame"
              width="100%"
              height="100%"
              display="initial"
              position="relative"
              className="iframe p-0 dashboard-calendar-iframe"
            />
          </React.Fragment>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Calendar;
