import React from "react";
import usePalette from "../../../hooks/usePalette";
import { Line } from "react-chartjs-2";

export type BarChartProps = {
  labels: string[];
  datasets: Array<{
    label: string;
    data: (number | null)[];
  }>;
};

export const BarChart: React.FC<BarChartProps> = ({ labels, datasets }) => {
  const palette = usePalette();
  const colors = React.useMemo(() => {
    return [palette.primary, palette.secondary, palette.success];
  }, [palette]);

  const chartData = {
    labels,
    datasets: datasets.map((dataset, index) => {
      return {
        label: dataset.label,
        fill: false,
        backgroundColor: "transparent",
        borderColor: colors[index % colors.length],
        data: dataset.data,
      };
    }),
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: "rgba(0,0,0,0.05)",
          },
        },
      ],
      yAxes: [
        {
          display: true,
          borderDash: [5, 5],
          gridLines: {
            color: "rgba(0,0,0,0)",
            fontColor: "#fff",
          },
        },
      ],
    },
  };

  return <Line data={chartData} options={options} />;
};
