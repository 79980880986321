import { Button, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

export const FirmsList: React.FC<{}> = () => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">Firms</Card.Title>
    </Card.Header>
    <Card.Body>
      <Table>
        <thead>
          <tr>
            <th>Firm Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ankerstar Wealth</td>
            <td>
              <Link to="/pages/firms/firm-id" className="btn btn-primary me-2">
                Advisors
              </Link>
              <Button>Rep Codes</Button>
            </td>
          </tr>
          <tr>
            <td>Addams Family Financial Practice</td>
            <td>
              <Link to="/pages/firms/firm-id" className="btn btn-primary me-2">
                Advisors
              </Link>
              <Button>Rep Codes</Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </Card.Body>
  </Card>
);
