import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container } from "react-bootstrap";
import { useApiEffect } from "../../../services/api";
import { PendingHouseholdState } from "./types";
import { ExternalRef, Household } from "../../../models/household";
import { PendingLinksBody } from "./PendingLinksBody";
import {
  ExternalRefSyncStatus,
  useSaveExternalRefs,
} from "../../../models/externalRef";

export const PendingHouseholds = () => {
  const { data, loading } = useApiEffect<Household[]>(
    "Households?externalRefs=false"
  );

  const [householdExternalRefStates, setHouseholdExternalRefStates] =
    React.useState<{
      [id: number]: PendingHouseholdState;
    }>({});
  const onUpdate = React.useCallback(
    (externalRef: ExternalRef, state: PendingHouseholdState) => {
      setHouseholdExternalRefStates({
        ...householdExternalRefStates,
        [externalRef.id]: state,
      });
    },
    [householdExternalRefStates, setHouseholdExternalRefStates]
  );

  const [savingState, setSavingState] = React.useState<
    "idle" | "saving" | "saved" | "error"
  >("idle");
  const saveExternalRefs = useSaveExternalRefs();
  const save = React.useCallback(async () => {
    if (!data) return;

    setSavingState("saving");
    const externalRefsById = new Map(
      data.flatMap((h) => h.externalRefs ?? []).map((r) => [r.id, r])
    );

    const updateData = Object.entries(householdExternalRefStates).map(
      ([externalRefId, state]) => {
        const externalRef = externalRefsById.get(Number(externalRefId));
        return {
          id: Number(externalRefId),
          externalId: externalRef?.externalId,
          name: externalRef?.name,
          syncStatus:
            state === "approved"
              ? "Approved"
              : ("Ignore" as ExternalRefSyncStatus),
        };
      }
    );

    const result = await saveExternalRefs(updateData);
    if (result.error) {
      setSavingState("error");
    } else {
      setSavingState("saved");
    }
  }, [householdExternalRefStates, saveExternalRefs, data]);

  return (
    <React.Fragment>
      <Helmet title="Household Linking" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Partner App Linking</h1>
        <Card>
          <Card.Header>
            <Card.Title>Pending Household Links</Card.Title>
            <p className="mb-0">
              We were able to automatically match some households from
              BlackDiamond. Please review the matches to ensure they are correct
            </p>
          </Card.Header>
          {data && (
            <PendingLinksBody
              data={data}
              statuses={householdExternalRefStates}
              onUpdate={onUpdate}
              saving={savingState === "saving"}
              onClickSave={save}
              showSavedMessage={savingState === "saved"}
              showSaveErrorMessage={savingState === "error"}
            />
          )}
          {loading && (
            <Card.Body>
              <p>Loading...</p>
            </Card.Body>
          )}
        </Card>
      </Container>
    </React.Fragment>
  );
};
