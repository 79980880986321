import React from "react";
import usePalette from "../../../hooks/usePalette";
import { Card } from "react-bootstrap";
import { ModelAUMYearInfo } from "../models-trading/data";
import { Line } from "react-chartjs-2";

type AUMOverTimeProps = {
  data: ModelAUMYearInfo[];
};

export const AUMOverTime: React.FC<AUMOverTimeProps> = ({ data }) => {
  const palette = usePalette();

  const [labels, amounts] = React.useMemo(() => {
    return [data.map((r) => r.year), data.map((r) => Number(r.amount))];
  }, [data]);

  const chartData = {
    labels,
    datasets: [
      {
        label: "AUM ($)",
        fill: true,
        backgroundColor: "transparent",
        borderColor: palette.primary,
        data: amounts,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: "rgba(0,0,0,0.05)",
          },
        },
      ],
      yAxes: [
        {
          display: true,
          borderDash: [5, 5],
          gridLines: {
            color: "rgba(0,0,0,0)",
            fontColor: "#fff",
          },
        },
      ],
    },
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>AUM over Time</Card.Title>
      </Card.Header>
      <Card.Body>
        <Line data={chartData} options={options} />
      </Card.Body>
    </Card>
  );
};
