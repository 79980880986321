import React from "react";
import { Card } from "react-bootstrap";
import { UpcomingDate } from "./types";

type UpcomingDatesProps = {
  dates: UpcomingDate[];
};

export const UpcomingDates: React.FC<UpcomingDatesProps> = ({ dates }) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title>Upcoming Special Dates</Card.Title>
      </Card.Header>
      <Card.Body>
        {dates.map((date) => (
          <>
            <div className="mb-3">
              <h6 className="mb-1">{date.label}</h6>
              <div>
                <small>{date.dateLabel}</small>
              </div>
            </div>
            <hr className="my-0 mb-3" />
          </>
        ))}
      </Card.Body>
    </Card>
  );
};
