import React from "react";
import { useOrganization } from "../../../contexts/ProfileContext";

export const MoneyGuideProLink: React.FC = () => {
  const organization = useOrganization();

  return (
    <React.Fragment>
      <div className="d-grid">
        <a
          href={`${process.env.REACT_APP_MGP_SSO_URL}?organization=${organization}`}
          className="btn btn-primary vw-50"
          target="_blank"
          rel="noreferrer noopener"
        >
          Launch Money Guide Pro
        </a>
      </div>
    </React.Fragment>
  );
};
