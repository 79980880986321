import React from "react";
import { Bar } from "react-chartjs-2";
import usePalette from "../../../hooks/usePalette";

const BarChart = () => {
  const palette = usePalette();

  const data = {
    labels: ["2024", "2025", "2026", "2027"],
    datasets: [
      {
        label: "Last year",
        backgroundColor: palette.primary,
        borderColor: palette.primary,
        hoverBackgroundColor: palette.primary,
        hoverBorderColor: palette.primary,
        data: [12, 53, 23, 54],
        barPercentage: 0.325,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      // yAxes: [
      //   {
      //     gridLines: {
      //       display: false,
      //     },
      //     ticks: {
      //       stepSize: 20,
      //     },
      //     stacked: true,
      //   },
      // ],
      xAxes: [
        {
          gridLines: {
            color: "transparent",
          },
        },
      ],
    },
  };

  return (
    <div className="align-self-center w-100">
      <div className="chart chart-lg">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default BarChart;
