import React from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Tab, Nav, Container, Row, Breadcrumb } from "react-bootstrap";
import { useSingleClient } from "../clients/hooks";
import { OverviewTab } from "./OverviewTab";
import { CRMTab } from "./crm-tab";
import { ModelsAndTradingTab } from "./model-trading-tab";

export const HouseholdOverview = () => {
  const { id } = useParams<"id">();
  const client = useSingleClient(id ? Number(id) : 0);
  const title = `${client.houseHoldName} Household`;

  return (
    <React.Fragment>
      <Helmet title={title} />
      <Container fluid className="p-0">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/pages/clients">Households</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{title}</Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="h3 mb-3">{title}</h1>
        <div className={"tab Default"}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="overview">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="overview">Overview</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="crm">CRM</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="financial-planning">
                  Financial Planning
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="billing">Billing</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="performance-reports">
                  Performance Reports
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="client-portal">Client Portal</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="risk">Risk</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="models-trading">
                  Models &amp; Trading
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="overview">
                <OverviewTab />
              </Tab.Pane>
              <Tab.Pane eventKey="financial-planning">
                <h4 className="tab-title">Financial Planning</h4>
              </Tab.Pane>
              <Tab.Pane eventKey="risk">
                <h4 className="tab-title">Risk</h4>
              </Tab.Pane>
              <Tab.Pane eventKey="crm">
                <CRMTab client={client} />
              </Tab.Pane>
              <Tab.Pane eventKey="models-trading">
                <ModelsAndTradingTab client={client} />
              </Tab.Pane>
              <Tab.Pane eventKey="client-portal">
                <h4 className="tab-title">Client Portal</h4>
              </Tab.Pane>
              <Tab.Pane eventKey="billing">
                <h4 className="tab-title">Billing</h4>
              </Tab.Pane>
              <Tab.Pane eventKey="performance-reports">
                <h4 className="tab-title">Performance Reports</h4>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <Row></Row>
      </Container>
    </React.Fragment>
  );
};
