import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, useField } from "formik";
import { Alert, Button, Card, Form } from "react-bootstrap";
import { usePartnerApps } from "./hooks";

export const Checkbox: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  const [_field, meta, helpers] = useField("pushToApps");
  return (
    <Form.Check
      type="checkbox"
      label={label}
      isInvalid={Boolean(meta.touched && meta.error)}
      onBlur={() => helpers.setTouched(true)}
      onChange={(_e) => {
        const set = new Set<string>(meta.value);
        if (set.has(value)) {
          set.delete(value);
        } else {
          set.add(value);
        }
        helpers.setValue(Array.from(set) as any);
        // form.setFieldTouched(field.name, true);
      }}
      name="pushToApps"
    />
  );
};

export const CreateHousehold = () => {
  const navigate = useNavigate();
  const parnterApps = usePartnerApps();

  return (
    <Card>
      <Card.Header>
        <Card.Title>Create Household</Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          initialValues={{
            name: "",
            pushToApps: [] as string[],
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required("Name is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              console.log(values);
              navigate("/pages/clients");
            } catch (error: any) {
              const message = error.message || "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  size="lg"
                  name="name"
                  placeholder="Adams Family"
                  value={values.name}
                  isInvalid={Boolean(touched.name && errors.name)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <h4>Push to Partner Apps</h4>
              <Form.Group className="mb-3">
                <Form.Label>Apps</Form.Label>
                {parnterApps.map((app) => (
                  <Checkbox key={app} value={app} label={app} />
                ))}
                {!!touched.pushToApps && (
                  <Form.Control.Feedback type="invalid">
                    {errors.pushToApps}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <div className="mt-3">
                <Button
                  type="submit"
                  variant="success"
                  size="lg"
                  disabled={isSubmitting}
                >
                  Create
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};
