import React from "react";

import { Form, ListGroup, ListGroupItem } from "react-bootstrap";
const MONEYGUIDE_CLIENTS = [
  {
    id: "MG-12345",
    name: "The Archers",
  },
  {
    id: "MG-54321",
    name: "Archer Family",
  },
  {
    id: "MG-129876",
    name: "Baratheon, A.",
  },
  {
    id: "MG-129876",
    name: "Lannister, B",
  },
];

type MoneyGuideProClient = typeof MONEYGUIDE_CLIENTS[0];

type EntitySearchProps = {
  label: string;
};

export const EntitySearch: React.FC<EntitySearchProps> = ({ label }) => {
  const [query, setQuery] = React.useState("");
  const displayedClients = React.useMemo(() => {
    if (!query) {
      return [];
    }

    return MONEYGUIDE_CLIENTS.filter((c) =>
      c.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
    );
  }, [query]);

  const [selectedClient, setSelectedClient] =
    React.useState<MoneyGuideProClient | null>(null);

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{label}</Form.Label>
        <Form.Control
          onChange={(e) => setQuery(e.currentTarget.value)}
          value={query}
        />
      </Form.Group>
      <ListGroup>
        {displayedClients.map((displayedClient) => (
          <ListGroupItem
            onClick={() => setSelectedClient(displayedClient)}
            variant={selectedClient === displayedClient ? "success" : undefined}
          >
            {displayedClient.name}{" "}
            <span className="text-success">(ID: {displayedClient.id})</span>
          </ListGroupItem>
        ))}
      </ListGroup>
    </>
  );
};
