import React from "react";

import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

type AlertsProps = {};

export const Alerts: React.FC<AlertsProps> = () => {
  return (
    <Card>
      <Card.Header>
        <Card.Title>Alerts</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <h3 className="mb-2">15</h3>
            <small className="mb-2">Clients</small>
          </Col>
          <Col>
            <h3 className="mb-2">13</h3>
            <small className="mb-2">Prospects</small>
          </Col>
          <Col>
            <Link to="/pages/tasks">
              <h3 className="mb-2">4</h3>
              <small className="mb-2">Open Tasks</small>
            </Link>
          </Col>
        </Row>
        <h6 className="mt-4 mb-3">Households</h6>
        <Row>
          <Col>
            <h3 className="mb-2">11</h3>
            <small className="mb-2">Active in the last 60 days</small>
          </Col>
          <Col>
            <h3 className="mb-2">16</h3>
            <small className="mb-2">Active in the last 3 months</small>
          </Col>
          <Col>
            <h3 className="mb-2">25</h3>
            <small className="mb-2">Active in the last 6 months</small>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
