import React from "react";

export const ElementsLink: React.FC = () => {
  return (
    <React.Fragment>
      <div className="d-grid">
        <a
          href={process.env.REACT_APP_ELEMENTS_SSO_URL}
          className="btn btn-primary vw-50"
          target="_blank"
          rel="noreferrer noopener"
        >
          Launch Customer Relationship Manager
        </a>
      </div>
    </React.Fragment>
  );
};
