import * as React from "react";
import { Row, Col, Table } from "react-bootstrap";
import EmbeddedData from "./EmbeddedData";

type OverviewTabProps = {};

export const OverviewTab: React.FC<OverviewTabProps> = () => {
  return (
    <>
      <Row>
        <Col lg="12" className="d-flex">
          <EmbeddedData />
        </Col>
      </Row>

      <h3 className="mt-5 mb-3">Accounts</h3>
      <Table>
        <thead>
          <tr>
            <th>Account Name</th>
            <th>Account Type</th>
            <th>Account Number</th>
            <th>Dollar Amount</th>
            <th>Performance Risk Score</th>
            <th>Cash Amount % + $</th>
            <th>Fees</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Account Name</td>
            <td>Account Type</td>
            <td>Account Number</td>
            <td>Dollar Amount</td>
            <td>Performance Risk Score</td>
            <td>Cash Amount % + $</td>
            <td>Fees</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
