import { Badge, Card } from "react-bootstrap";
import "./Calendar.scss";
import { useApiEffect } from "../../services/api";
import { ArrowUp, ArrowDown } from "react-feather";
import { useMemo } from "react";

export interface TickerInfo {
  symbol: string;
  currentPrice: number;
  change?: number;
  pecentChange?: number;
  highPrice?: number;
  lowPrice?: number;
  openPrice?: number;
  previousClosePrice: number;
  timestamp: number;
}

const SYMBOL_DISPLAY: Record<string, string> = {
  "^GSPC": "S&P",
  "^DJI": "Dow Jones",
  "^IXIC": "Nasdaq",
  "^RUT": "Russell 2000",
  "^FTSE": "FTSE 100",
  "^N225": "Nikkei 225",
};

const Ticker: React.FC<{ ticker: TickerInfo }> = ({ ticker }) => {
  const percentChanged = useMemo(() => {
    if (ticker.pecentChange) {
      return ticker.pecentChange;
    }

    return (
      ((ticker.currentPrice - ticker.previousClosePrice) /
        ticker.previousClosePrice) *
      100
    );
  }, [ticker]);

  return (
    <div className="me-5">
      <h3>{SYMBOL_DISPLAY[ticker.symbol] ?? ticker.symbol}</h3>
      <div className={`text-${percentChanged > 0 ? "success" : "danger"}`}>
        ${ticker.currentPrice}
      </div>
      <div>
        <Badge bg={percentChanged > 0 ? "success" : "danger"}>
          {percentChanged > 0 ? <ArrowUp size={10} /> : <ArrowDown size={10} />}
          {percentChanged.toFixed(1)}%
        </Badge>
      </div>
    </div>
  );
};

const WatchList = () => {
  const { data } = useApiEffect<TickerInfo[]>("MarketData/Quotes", "POST", [
    "^GSPC",
    // "DJI",
    "^DJI",
    "^IXIC",
    "^RUT",
    "^FTSE",
    "^N225",
  ]);

  if (!data) {
    return null;
  }

  return (
    <Card className="flex-fill w-100">
      <Card.Body className="d-flex">
        <div className="d-flex">
          {data.map((ticker) => (
            <Ticker ticker={ticker} />
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default WatchList;
