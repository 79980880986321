import { Button, Card } from "react-bootstrap";

type InvalidUserCardProps = {
  onClickRelogin(): void;
};

export const InvalidUserCard: React.FC<InvalidUserCardProps> = ({
  onClickRelogin,
}) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title>Invalid Account Setup</Card.Title>
      </Card.Header>
      <Card.Body>
        <p>User does not have any relevant roles, so cannot complete login</p>
        <Button
          onClickCapture={onClickRelogin}
          variant="primary"
          onClick={onClickRelogin}
        >
          Relogin
        </Button>
      </Card.Body>
    </Card>
  );
};
