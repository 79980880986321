import { Table } from "react-bootstrap";

export const CalendarYearReturn = () => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Calendar Year Return</th>
          <th>2013</th>
          <th>2014</th>
          <th>2015</th>
          <th>2016</th>
          <th>2017</th>
          <th>2018</th>
          <th>2019</th>
          <th>2020</th>
          <th>2021</th>
          <th>2022</th>
          <th>YTD</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Queen Ursula Portfolio</td>
          <td>13.78%</td>
          <td>7.42%</td>
          <td>-2.64%</td>
          <td>9.52%</td>
          <td>17.73%</td>
          <td>-4.58%</td>
          <td>21.71%</td>
          <td>13.94%</td>
          <td>13.38%</td>
          <td>-13.13%</td>
          <td>5.43%</td>
        </tr>
        <tr>
          <td>60/40 Model</td>
          <td>15.58%</td>
          <td>7.52%</td>
          <td>-0.01%</td>
          <td>8.01%</td>
          <td>13.84%</td>
          <td>-4.47%</td>
          <td>20.91%</td>
          <td>12.88%</td>
          <td>14.88%</td>
          <td>-14.87%</td>
          <td>6.19%</td>
        </tr>
      </tbody>
    </Table>
  );
};
