import React from "react";

const SidebarFooter = () => {
  return (
    <div className="sidebar-cta">
      <div className="sidebar-cta-content">
        <strong className="d-inline-block mb-2">Opportunities Report</strong>
        <div className="mb-3 text-sm">
          Your monthly client opportunities report is ready for download!
        </div>

        <div className="d-grid">
          <a
            href="https://advizorstack.com/"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer noopener"
          >
            Download
          </a>
        </div>
      </div>
    </div>
  );
};

export default SidebarFooter;
