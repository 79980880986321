import { Table } from "react-bootstrap";

export const UpsideDown = () => {
  return (
    <Table>
      <thead>
        <tr>
          <th># of Periods</th>
          <th></th>
          <th>Average Return</th>
          <th></th>
          <th>Month</th>
          <th></th>
          <th>1-Year</th>
          <th></th>
          <th>Market Benchmark</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Upside / Downside</td>
          <td>Up</td>
          <td>Down</td>
          <td>Up</td>
          <td>Down</td>
          <td>Best</td>
          <td>Worst</td>
          <td>Best</td>
          <td>Worst</td>
          <td>Up Capture</td>
          <td>Down Capture</td>
          <td>Up/ Down Ratio</td>
        </tr>
        <tr>
          <td>Queen Ursula Portfolio</td>
          <td>82</td>
          <td>42</td>
          <td>2.25%</td>
          <td>-2.49%</td>
          <td>8.48%</td>
          <td>-10.21%</td>
          <td>38.21%</td>
          <td>-14.46%</td>
          <td>104.70%</td>
          <td>104.52</td>
          <td>1.00%</td>
        </tr>
        <tr>
          <td>60/40 Model</td>
          <td>85</td>
          <td>39</td>
          <td>2.05%</td>
          <td>-2.47%</td>
          <td>7.83%</td>
          <td>-10.42%</td>
          <td>35.73%</td>
          <td>-15.04%</td>
          <td>100%</td>
          <td>100%</td>
          <td>N/A</td>
        </tr>
      </tbody>
    </Table>
  );
};
