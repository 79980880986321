import * as React from "react";
import { Row, Col, Table } from "react-bootstrap";
import { Client } from "../../clients/types";
import { AUMChart } from "./AUMChart";
import { AssetAllocation } from "./AssetAllocation";
import modelPerf from "./model-perf.json";
import householdPerf from "./model-perf.json";
import { PortfolioStats } from "./PortfolioStats";
import { TrailingYearReturns } from "./TrailingYearReturns";
import { CalendarYearReturn } from "./CalendarYearReturn";
import { RiskReturn } from "./RiskReturn";
import { UpsideDown } from "./UpsideDown";
import { DrawdownOfReturn } from "./DrawdownOfReturn";

type AssetAllocationProps = {
  client: Client;
};

export const ModelsAndTradingTab: React.FC<AssetAllocationProps> = () => {
  return (
    <>
      <h4 className="tab-title">Models &amp; Trading</h4>
      <Row>
        <Col sm={3}>
          <h6 className="mb-3">Asset Allocation</h6>
          <AssetAllocation />
        </Col>
        <Col sm={9}>
          <h6 className="mb-3">AUM</h6>
          <AUMChart />
        </Col>
      </Row>
      <Row>
        <Col>
          <PortfolioStats />
        </Col>
      </Row>
      <Row>
        <Col>
          <TrailingYearReturns />
        </Col>
      </Row>
      <Row>
        <Col>
          <CalendarYearReturn />
        </Col>
      </Row>
      <Row>
        <Col>
          <RiskReturn />
        </Col>
      </Row>
      <Row>
        <Col>
          <UpsideDown />
        </Col>
      </Row>
      <Row>
        <Col>
          <DrawdownOfReturn />
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <h6 className="mb-3">Model Performance</h6>
          <Table>
            <thead>
              <tr>
                <th>Year</th>
                <th>Jan</th>
                <th>Feb</th>
                <th>Mar</th>
                <th>April</th>
                <th>May</th>
                <th>Jun</th>
                <th>Jul</th>
                <th>Aug</th>
                <th>Sep</th>
                <th>Oct</th>
                <th>Nov</th>
                <th>Dec</th>
              </tr>
            </thead>
            <tbody>
              {modelPerf.map((row) => (
                <tr>
                  <td>{row.Year}</td>
                  <td>{row.Jan}</td>
                  <td>{row.Feb}</td>
                  <td>{row.Mar}</td>
                  <td>{row.April}</td>
                  <td>{row.May}</td>
                  <td>{row.Jun}</td>
                  <td>{row.Jul}</td>
                  <td>{row.Aug}</td>
                  <td>{row.Sep}</td>
                  <td>{row.Oct}</td>
                  <td>{row.Nov}</td>
                  <td>{row.Dec}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <h6 className="mt-3 mb-3">Household Performance</h6>
          <Table>
            <thead>
              <tr>
                <th>Year</th>
                <th>Jan</th>
                <th>Feb</th>
                <th>Mar</th>
                <th>April</th>
                <th>May</th>
                <th>Jun</th>
                <th>Jul</th>
                <th>Aug</th>
                <th>Sep</th>
                <th>Oct</th>
                <th>Nov</th>
                <th>Dec</th>
              </tr>
            </thead>
            <tbody>
              {householdPerf.map((row) => (
                <tr>
                  <td>{row.Year}</td>
                  <td>{row.Jan}</td>
                  <td>{row.Feb}</td>
                  <td>{row.Mar}</td>
                  <td>{row.April}</td>
                  <td>{row.May}</td>
                  <td>{row.Jun}</td>
                  <td>{row.Jul}</td>
                  <td>{row.Aug}</td>
                  <td>{row.Sep}</td>
                  <td>{row.Oct}</td>
                  <td>{row.Nov}</td>
                  <td>{row.Dec}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};
