import React from "react";
import classNames from "classnames";
import { CRMClient } from "./types";

type ClientRowProps = {
  client: CRMClient;
  onClickClient(client: CRMClient): void;
  isSelected: boolean;
  index: number;
};

export const ClientRow: React.FC<ClientRowProps> = ({
  client,
  onClickClient,
  isSelected,
}) => {
  const onClickRow = React.useCallback(() => {
    onClickClient(client);
  }, [client, onClickClient]);

  const className = classNames(isSelected && "table-info");

  return (
    <tr className={className} onClick={onClickRow}>
      <td>{client.houseHoldName}</td>
      <td>{client.clientName}</td>
      <td>{client.household}</td>
      <td>{client.contactType}</td>
      <td>{client.openTasks}</td>
      <td>{client.lastCRMActivity}</td>
    </tr>
  );
};
