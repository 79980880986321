import React from "react";
import { Card, Table } from "react-bootstrap";
import { CRMClient } from "./types";
import { ClientRow } from "./ClientRow";

type ClientsTableProps = {
  clients: CRMClient[];
  selectedClient: CRMClient;
  onClickClient(client: CRMClient): void;
};

export const ClientsTable: React.FC<ClientsTableProps> = ({
  clients,
  selectedClient,
  onClickClient,
}) => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">Clients</Card.Title>
    </Card.Header>
    <Card.Body>
      <Table className="mb-0">
        <thead>
          <tr>
            <th>Household</th>
            <th>Client Name</th>
            <th>Household</th>
            <th>Contact Type</th>
            <th>Open Tasks</th>
            <th>Last CRM Activity</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client, index) => (
            <ClientRow
              client={client}
              isSelected={selectedClient === client}
              onClickClient={onClickClient}
              index={index}
            />
          ))}
        </tbody>
      </Table>
    </Card.Body>
  </Card>
);
