import React from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { FirmAssetAllocation } from "../models-trading/FirmAssetAllocation";
import { RegionAllocation } from "../models-trading/RegionAllocation";
import { MODELS_DATA } from "../models-trading/data";
import { useParams } from "react-router-dom";
import { AUMOverTime } from "./AUMOverTime";
import { HoldingDetailsTab } from "./HoldingDetailsTab";
import { PeformanceTab } from "./PeformanceTab";
import { RiskAndMetricsTab } from "./RiskAndMetricsTab";

export const ModelsAndTradeHoldings = () => {
  const { modelId } = useParams<{ modelId: string }>();
  const model = MODELS_DATA[modelId ? Number(modelId) : 0];

  return (
    <React.Fragment>
      <Helmet title="Models & Trades" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Models & Trade Management</h1>
        <Row>
          <Col md={4}>
            <FirmAssetAllocation />
          </Col>
          <Col md={4}>
            <RegionAllocation />
          </Col>
          <Col md={4}>
            <AUMOverTime data={model.aumByYear} />
          </Col>
        </Row>
        <div className={"tab Default"}>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="holdingDetails"
          >
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="holdingDetails">Holding Details</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="performance">Performance</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="riskMetrics">Risk Metrics</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="holdingDetails">
                <HoldingDetailsTab />
              </Tab.Pane>
              <Tab.Pane eventKey="performance">
                <PeformanceTab />
              </Tab.Pane>
              <Tab.Pane eventKey="riskMetrics">
                <RiskAndMetricsTab />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </Container>
    </React.Fragment>
  );
};
