import * as React from "react";
import { Row, Col, Card, Badge, Button, Table } from "react-bootstrap";
import { Calendar, DollarSign } from "react-feather";
import { formatDate } from "../../../../utils/date-formatting";
import { Client } from "../../clients/types";
import { Activity } from "./Activity";

type CRMTabProps = {
  client: Client;
};

export const CRMTab: React.FC<CRMTabProps> = ({ client }) => {
  return (
    <>
      <div className="mb-3">
        <h3>{client.houseHoldName}</h3>
        <div className="mt-1">
          <Badge className="me-2" bg="info">
            College 529 Plan
          </Badge>
        </div>
      </div>
      <Row>
        <Col sm={4}>
          <Card>
            <Card.Body>
              <Card.Title>Contact Info</Card.Title>
              <Row className="g-0">
                <Col sm="6">
                  <div>{client.address}</div>
                  <div>{client.addressLine2}</div>
                </Col>
                <Col sm="6">
                  <div>{client.phone}</div>
                </Col>
              </Row>
            </Card.Body>

            <hr className="my-0" />

            <Card.Body>
              <Card.Title>Family Members</Card.Title>
              <Row className="mt-4">
                <Col xs={8}>
                  <h6 className="mb-1">Jetsam "Jetty" Ursula</h6>
                  <small>Child</small>
                </Col>
                <Col xs={4} className="text-end">
                  <Button>View</Button>
                </Col>
              </Row>
            </Card.Body>

            <hr className="my-0" />

            <Card.Body>
              <Card.Title>Background Info</Card.Title>
              <p>{client.notes}</p>

              <Table size="sm" className="my-2">
                <tbody>
                  <tr>
                    <th>Marital Status</th>
                    <td>Single</td>
                  </tr>
                  <tr>
                    <th>Company</th>
                    <td>{client.occupation?.company ?? "n/a"}</td>
                  </tr>
                  <tr>
                    <th>Job Title</th>
                    <td>{client.occupation?.title ?? "n/a"}</td>
                  </tr>
                  <tr>
                    <th>Client Since</th>
                    <td>
                      {client.clientSince
                        ? formatDate(client.clientSince, "MMMM Do YYYY")
                        : "n/a"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={4}>
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <div className="d-flex align-items-start">
                <div className="flex-grow-1 text-center">
                  <h1 className="mb-2">
                    {client.oppourtunities?.openOppourtunitiesCount}
                  </h1>
                  <p className="mb-2">Oppourtunities</p>
                  <div className="mb-0">
                    <Badge bg="" className="badge-soft-success me-2">
                      ${client.oppourtunities?.cumulativeValue}
                    </Badge>
                    <span className="text-muted">in oppourtunity value</span>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title>Oppourtunities</Card.Title>
              {client.oppourtunities?.oppourtunityEntities.map((opp) => (
                <Row className="mt-4">
                  <Col xs={2}>
                    <div className="stat">
                      <DollarSign className="align-middle text-success" />
                    </div>
                  </Col>
                  <Col xs={8}>
                    <h6 className="mb-1">{opp.name}</h6>
                    <div>
                      <small>Target close: {opp.targetClose}</small>
                    </div>
                    <div>
                      <small>${opp.value} (AUM)</small>
                    </div>
                  </Col>
                </Row>
              ))}
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title>Events</Card.Title>
              {client.events?.map((event) => (
                <Row className="mt-4">
                  <Col xs={2}>
                    <div className="stat">
                      <Calendar className="align-middle text-success" />
                    </div>
                  </Col>
                  <Col xs={8}>
                    <h6 className="mb-1">{event.title}</h6>
                    <div>
                      <small>
                        {event.startTime} to {event.endTime}
                      </small>
                    </div>
                  </Col>
                </Row>
              ))}
            </Card.Body>
          </Card>
        </Col>
        <Col sm={4}>
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <div className="d-flex align-items-start">
                <div className="flex-grow-1 text-center">
                  <h1 className="mb-2">{client.tasks?.openTaskCount}</h1>
                  <p className="mb-2">Tasks</p>
                </div>
              </div>
            </Card.Body>
          </Card>

          <Activity />
        </Col>
      </Row>
    </>
  );
};
