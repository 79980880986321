import { Card, Table } from "react-bootstrap";

export const AdvisorList: React.FC<{}> = () => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">Firm Users</Card.Title>
    </Card.Header>
    <Card.Body>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Teams</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Steve Ankerstar</td>
            <td>vipservices@ankerstarwealth.com</td>
            <td>Admin</td>
            <td>
              <i>Unassigned</i>
            </td>
          </tr>
        </tbody>
      </Table>
    </Card.Body>
  </Card>
);
