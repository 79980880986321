import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

export interface AppError {
  message: string;
}

export interface ApiResponse<T> {
  data: T | null;
  error: AppError | null;
}

export const callExternalApi = async <T,>(options: {
  config: AxiosRequestConfig;
}): Promise<ApiResponse<T>> => {
  try {
    const response: AxiosResponse = await axios(options.config);
    const { data } = response;

    return {
      data,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      const { response } = axiosError;

      let message = "http request failed";

      if (response && response.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response && response.data && (response.data as AppError).message) {
        message = (response.data as AppError).message;
      }

      return {
        data: null,
        error: {
          message,
        },
      };
    }

    return {
      data: null,
      error: {
        message: (error as Error).message,
      },
    };
  }
};

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const useApi = <T,>(endpoint: string, method: string = "GET") => {
  const { getAccessTokenSilently } = useAuth0();

  const makeRequest = React.useCallback(
    async (body: unknown | null = null) => {
      const accessToken = await getAccessTokenSilently();
      const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/${endpoint}`,
        method,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      if (body) {
        config.data = body;
      }

      return await callExternalApi<T>({ config });
      // eslint-disable-next-line
  }, [getAccessTokenSilently]);

  return makeRequest;
};

export const useApiEffect = <T,>(
  endpoint: string,
  method: string = "GET",
  body: unknown | null = null
) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<AppError | null>(null);
  const [data, setData] = React.useState<T | null>(null);
  const fetchFromApi = useApi<T>(endpoint, method);

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await fetchFromApi(body);
      if (response.error) {
        setError(response.error);
      } else if (response.data) {
        setData(response.data);
      }
      setLoading(false);
    })();

    // eslint-disable-next-line
  }, []);

  return { loading, data, error };
};
