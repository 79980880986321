import React from "react";

import { Form, ListGroup, ListGroupItem } from "react-bootstrap";

type GenericEntity = { id: number; name: string };

type EntitySearchProps<T extends GenericEntity> = {
  refs: T[] | null;
  onSelect(entity: T): void;
};

export const EntitySearch = <T extends GenericEntity>({
  onSelect,
  refs,
}: EntitySearchProps<T>) => {
  const [query, setQuery] = React.useState("");
  const displayedClients = React.useMemo(() => {
    if (!query) {
      return [];
    }

    return (
      refs?.filter((c) =>
        c.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
      ) ?? []
    );
  }, [query, refs]);

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Search Elements Households</Form.Label>
        <Form.Control
          onChange={(e) => setQuery(e.currentTarget.value)}
          value={query}
        />
      </Form.Group>
      <ListGroup>
        {displayedClients.map((displayedClient) => (
          <ListGroupItem onClick={() => onSelect(displayedClient)}>
            {displayedClient.name}{" "}
            <span className="text-success">(ID: {displayedClient.id})</span>
          </ListGroupItem>
        ))}
      </ListGroup>
    </>
  );
};
