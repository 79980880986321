import React from "react";
import { Helmet } from "react-helmet-async";
import avatar3 from "../../../assets/img/avatars/avatar-3.jpg";

import { Card, Container, Table, Col, Row } from "react-bootstrap";
import { useClientData } from "../clients/hooks";
import { useParams } from "react-router-dom";
import { useExternalRefs, useHousehold } from "./hooks";
import { usePartnerApps } from "../households/create-household/hooks";
import { PartnerAppSection } from "./PartnerAppSection";
import { useSaveExternalRefs } from "../../../models/externalRef";

export const HouseholdLinking = () => {
  const { householdId } = useParams<{ householdId: string }>();
  const clients = useClientData();
  const client = clients[Number(householdId)];
  const { household, groupedRefs } = useHousehold(Number(householdId));
  const { data: allRefs } = useExternalRefs("Household", "Unlinked");
  const partnerApps = usePartnerApps();
  const saveExternalRefs = useSaveExternalRefs();

  return (
    <React.Fragment>
      <Helmet title="Household Linking" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Partner App Linking</h1>
        <Row>
          <Col md={4}>
            <Card>
              <Card.Header>
                <Card.Title>{household?.name}</Card.Title>
                <h6 className="card-subtitle text-muted">{client.address}</h6>
              </Card.Header>
              <Card.Body>
                <Row className="g-0">
                  <Col sm="3" xl="12" className="col-xxl-3 text-center">
                    <img
                      src={avatar3}
                      width="64"
                      height="64"
                      className="rounded-circle mt-2"
                      alt={client.houseHoldName}
                    />
                  </Col>
                  <Col sm="9" xl="12" className="col-xxl-9">
                    <strong>Notes</strong>
                    <p>
                      Mrs. Urusla is a highly successful entrepreneur and
                      business owner seeking comprehensive financial services.
                    </p>
                  </Col>
                </Row>
                <Table size="sm" className="my-2">
                  <tbody>
                    <tr>
                      <th>Household Name</th>
                      <td>{client.houseHoldName}</td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>{client.address}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            {partnerApps.map((app) => (
              <PartnerAppSection
                name={app}
                allRefs={allRefs ? allRefs[app] : null}
                currentRefs={groupedRefs ? groupedRefs[app] : null}
                saveExternalRef={saveExternalRefs}
                key={app}
                household={household!}
              />
            ))}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
