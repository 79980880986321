import { UserRole } from "../../types/user";

type RoleWhiteOrBlacklist = {
  roleWhitelist?: UserRole[];
  roleBlacklist?: UserRole[];
};

export const isUserRoleAllowed = (
  roles: UserRole[],
  lists: RoleWhiteOrBlacklist
): boolean => {
  const userRoleSet = new Set(roles);
  if (lists.roleBlacklist) {
    return !lists.roleBlacklist.some((role) => userRoleSet.has(role));
  }

  if (lists.roleWhitelist) {
    return lists.roleWhitelist.some((role) => userRoleSet.has(role));
  }

  return true;
};
