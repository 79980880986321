import React from "react";
import { Helmet } from "react-helmet-async";

import { Button, Card, Container, Form, Table } from "react-bootstrap";
import { EntitySearch } from "./EntitySearch";

export const HouseholdImport = () => {
  return (
    <React.Fragment>
      <Helmet title="Household Import" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Household Import</h1>
        <Card>
          <Card.Header>
            <Card.Title>Unmapped Households</Card.Title>
            <h6 className="card-subtitle text-muted">
              We were unable to automatically match{" "}
              <span className="text-warning">42</span> households. Please review
              the results to see and map any households that are unlinked.
            </h6>
          </Card.Header>
          <Card.Body>
            <Table>
              <thead>
                <tr>
                  <th>Household Info</th>
                  <th>BlackDiamond Household</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-top">
                    <p>
                      <b>Archer Family</b>
                    </p>
                    <p>114 Main St</p>
                    <p>Fort Lauderdale, FL</p>
                  </td>
                  <td className="align-top">
                    <EntitySearch label="Search BlackDiamond Households" />
                    <div>or</div>
                    <Form.Group className="mb-3">
                      <Form.Check
                        type="checkbox"
                        id="checkbox"
                        label="Create in BlackDiamond"
                      />
                    </Form.Group>
                  </td>
                  <td className="align-top">
                    <span className="text-success">Archer Household</span>
                    <div>
                      <Button variant="danger">Unlink</Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="mt-3">
              <h4>Changes</h4>
              <p>
                <span className="text-danger">4</span> Households to unlink
              </p>
              <p>
                <span className="text-warning">6</span> Households to link
              </p>
              <p>
                <span className="text-success">3</span> Households to create in
                BlackDiamond
              </p>
              <p>
                <span className="text-success">2</span> Households to create in
                Elements
              </p>
              <div>
                <Button variant="success">Save Changes</Button>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};
