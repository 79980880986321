import React from "react";
import { useLocation } from "react-router-dom";

import reduceChildRoutes from "./reduceChildRoutes";
import { SidebarItemsType } from "../../types/sidebar";
import { useUserRoles } from "../../hooks/useUserRoles";

interface SidebarNavListProps {
  depth: number;
  pages: SidebarItemsType[];
}

const SidebarNavList = (props: SidebarNavListProps) => {
  const { pages, depth } = props;
  const router = useLocation();
  const currentRoute = router.pathname;
  const userRoles = useUserRoles();

  const childRoutes = pages.reduce(
    (items, page) =>
      reduceChildRoutes({ userRoles, items, page, currentRoute, depth }),
    [] as JSX.Element[]
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
