/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";

import { Badge, Collapse } from "react-bootstrap";

type BaseSidebarNavListItemProps = {
  className?: string;
  depth: number;
  icon: React.FC<any>;
  badge?: string;
  open?: boolean;
  title: string;
  children?: React.ReactNode;
};

type HrefSidebarNavListItemProps = BaseSidebarNavListItemProps & {
  href: string;
};

type ExternalLinkSidebarNavListItemProps = BaseSidebarNavListItemProps & {
  externalLink: string;
};

type SidebarNavListItemProps =
  | HrefSidebarNavListItemProps
  | ExternalLinkSidebarNavListItemProps;

const isHrefSidebarNavListItemProps = (
  props: SidebarNavListItemProps
): props is HrefSidebarNavListItemProps => {
  return !!(props as HrefSidebarNavListItemProps).href;
};

const SidebarNavListItem = (props: SidebarNavListItemProps) => {
  const {
    title,
    depth = 0,
    children,
    icon: Icon,
    badge,
    open: openProp = false,
  } = props;

  const [open, setOpen] = React.useState(openProp);

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  if (children) {
    return (
      <li className={`sidebar-item ${open ? "active" : ""}`}>
        <a
          className={`sidebar-link ${open ? "" : "collapsed"}`}
          data-bs-toggle="collapse"
          aria-expanded={open ? "true" : "false"}
          data-depth={depth}
          onClick={handleToggle}
        >
          {Icon && <Icon className="feather align-middle" />}{" "}
          <span className="align-middle" data-depth={depth}>
            {title}
          </span>
          {badge && (
            <Badge className="badge-sidebar-primary" bg="">
              {badge}
            </Badge>
          )}
          {open ? <div /> : <div />}
        </a>
        <Collapse in={open}>
          <ul className="sidebar-dropdown list-unstyled">{children}</ul>
        </Collapse>
      </li>
    );
  }

  if (!isHrefSidebarNavListItemProps(props)) {
    return (
      <li className="sidebar-item">
        <a
          data-depth={depth}
          href={props.externalLink}
          target="_blank"
          className="sidebar-link"
          rel="noreferrer"
        >
          {Icon && <Icon className="feather align-middle" />}{" "}
          <span className="align-middle" data-depth={depth}>
            {title}
          </span>
          {badge && (
            <Badge className="badge-sidebar-primary" bg="">
              {badge}
            </Badge>
          )}
        </a>
      </li>
    );
  }

  return (
    <li className="sidebar-item">
      <NavLink data-depth={depth} to={props.href} className="sidebar-link">
        {Icon && <Icon className="feather align-middle" />}{" "}
        <span className="align-middle" data-depth={depth}>
          {title}
        </span>
        {badge && (
          <Badge className="badge-sidebar-primary" bg="">
            {badge}
          </Badge>
        )}
      </NavLink>
    </li>
  );
};

export default SidebarNavListItem;
