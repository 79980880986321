import React from "react";

import { Badge, Card } from "react-bootstrap";
import { ClientTag } from "./types";

type TagsProps = {
  tags: ClientTag[];
};

export const Tags: React.FC<TagsProps> = ({ tags }) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title>Tags</Card.Title>
      </Card.Header>
      <Card.Body>
        {tags.map((tag) => (
          <Badge className="me-1" bg="secondary">
            {tag.label}
          </Badge>
        ))}
      </Card.Body>
    </Card>
  );
};
