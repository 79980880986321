import { Table } from "react-bootstrap";

export const TrailingYearReturns = () => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Trailing Year Returns</th>
          <th>YTD</th>
          <th>1 Year</th>
          <th>3 Years</th>
          <th>5 Years</th>
          <th>7 Years</th>
          <th>10 Years</th>
          <th>15 Years</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Queen Ursula Portfolio</td>
          <td>5.43%</td>
          <td>1.81%</td>
          <td>8.67%</td>
          <td>6.69%</td>
          <td>7.99%</td>
          <td>7.08%</td>
          <td>6.29%</td>
        </tr>
        <tr>
          <td>60/40 Model</td>
          <td>6.19%</td>
          <td>0.74%</td>
          <td>8.18%</td>
          <td>6.48%</td>
          <td>7.20%</td>
          <td>6.78%</td>
          <td>N/A</td>
        </tr>
      </tbody>
    </Table>
  );
};
