import { Badge, Form } from "react-bootstrap";
import { PendingHouseholdState } from "./types";
import { ExternalRef, Household } from "../../../models/household";
import { useCallback } from "react";

type PendingHouseholdRowProps = {
  household: Household;
  externalRef: ExternalRef;
  externalRefIndex: number;
  state: PendingHouseholdState;
  onUpdate(ref: ExternalRef, state: PendingHouseholdState): void;
};

const TR_CLASS: Record<PendingHouseholdState, string> = {
  approved: "table-success",
  unapproved: "table-warning",
  unselected: "",
};

export const PendingHouseholdRow: React.FC<PendingHouseholdRowProps> = ({
  state,
  household,
  onUpdate,
  externalRef,
  externalRefIndex,
}) => {
  const onSelect: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const value = e.target.value as PendingHouseholdState;
      onUpdate(externalRef, value);
    },
    [onUpdate, externalRef]
  );

  return (
    <tr className={TR_CLASS[state]}>
      {externalRefIndex === 0 && (
        <td rowSpan={household.externalRefs?.length}>{household.name}</td>
      )}
      <td>
        {externalRef.externalOrganization?.name} - {externalRef.id}
      </td>
      <td>
        {externalRef.name}
        <div>
          <Badge bg="success">ID: {externalRef.id}</Badge>
        </div>
      </td>
      <td>
        <Form.Check
          onChange={onSelect}
          value={"approved" as PendingHouseholdState}
          inline
          label="Approve"
          type="radio"
          name={`household-status-${household.id}`}
        />
        <Form.Check
          inline
          value={"unselected" as PendingHouseholdState}
          onChange={onSelect}
          label="Ignore"
          type="radio"
          name={`household-status-${household.id}`}
        />
      </td>
    </tr>
  );
};
