import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row } from "react-bootstrap";
import { ClientsTable } from "./ClientsTable";
import { FinancialPlanClient, useClientData } from "./hooks";
import { ClientDetail } from "./ClientDetail";
import { PieChart } from "./PieChart";
import BarChart from "./BarChart";
import { Alerts } from "./Alerts";

export const FinancialPlanningDashboard = () => {
  const clients = useClientData();
  const [selectedClient, setSelectedClient] =
    React.useState<FinancialPlanClient>(clients[0]);
  const onClickClient = React.useCallback(
    (client: FinancialPlanClient) => {
      setSelectedClient(client);
    },
    [setSelectedClient]
  );

  return (
    <React.Fragment>
      <Helmet title="Financial Planning Dashboard" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Financial Planning Dashboard</h1>
        <Row className="mb-4">
          <Col md={4}>
            <Card className="h-100">
              <Card.Header>
                <Card.Title>Potentional Revenue</Card.Title>
              </Card.Header>
              <Card.Body>
                <PieChart />
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="h-100">
              <Card.Header>
                <Card.Title>Client Retirements</Card.Title>
              </Card.Header>
              <Card.Body>
                <BarChart />
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Alerts />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <ClientsTable
              selectedClient={selectedClient}
              onClickClient={onClickClient}
              clients={clients}
            />
          </Col>
          <Col>
            <ClientDetail client={selectedClient} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
