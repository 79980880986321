import React from "react";

import { Badge, Button, Card } from "react-bootstrap";

import avatar2 from "../../assets/img/avatars/avatar-2.jpg";
import avatar3 from "../../assets/img/avatars/avatar-3.jpg";
import avatar4 from "../../assets/img/avatars/avatar-4.jpg";
import avatar5 from "../../assets/img/avatars/avatar-5.jpg";

const Feed = () => (
  <Card className="flex-fill w-100">
    <Card.Header>
      <Badge bg="info" className="float-end">
        Today
      </Badge>
      <Card.Title className="mb-0">Daily feed</Card.Title>
    </Card.Header>
    <Card.Body>
      <div className="d-flex">
        <img
          src={avatar5}
          width="36"
          height="36"
          className="rounded-circle me-2"
          alt="Shelley Bowman"
        />
        <div className="flex-grow-1">
          <small className="float-end text-navy">5m ago</small>
          <strong>Shelley Bowman</strong> Working on New Trust account for Queen
          Ursula.
          <br />
          <small className="text-muted">Today 12:03 pm</small>
          <br />
        </div>
      </div>

      <hr />
      <div className="d-flex">
        <img
          src={avatar3}
          width="36"
          height="36"
          className="rounded-circle me-2"
          alt="Andres Watson"
        />
        <div className="flex-grow-1">
          <small className="float-end text-navy">30m ago</small>
          <strong>Andrea Watson</strong> posted something on{" "}
          <strong>Shelley Bowman</strong>'s timeline
          <br />
          <small className="text-muted">Today 12:33 pm</small>
          <div className="border text-sm text-muted p-2 mt-1">
            Need help on the trust account?
          </div>
        </div>
      </div>

      <hr />
      <div className="d-flex">
        <img
          src={avatar2}
          width="36"
          height="36"
          className="rounded-circle me-2"
          alt="Archie Carlson"
        />
        <div className="flex-grow-1">
          <small className="float-end text-navy">2h ago</small>
          <strong>Archie Carlson</strong> Please remember to complete your
          continuing education.
          <br />
          <small className="text-muted">Today 10:08 am</small>
        </div>
      </div>

      <hr />
      <div className="d-flex">
        <img
          src={avatar4}
          width="36"
          height="36"
          className="rounded-circle me-2"
          alt="Gloria Greer"
        />
        <div className="flex-grow-1">
          <small className="float-end text-navy">2d ago</small>
          <strong>Gloria Greer</strong> Completed review of Ursula's new account
          form. Sent to custodian.
          <br />
          <small className="text-muted">Tuesday 4:35 pm</small>
        </div>
      </div>

      <hr />
      <div className="d-grid">
        <Button variant="primary">Load more</Button>
      </div>
    </Card.Body>
  </Card>
);

export default Feed;
