import React, { useState } from "react";
import Iframe from "react-iframe";

export const BlackDiamondSecurityMaster: React.FC = () => {
  const blackDiamondUrl = process.env.REACT_APP_BLACK_DIAMOND_SSO_URL;
  const [url] = useState(
    blackDiamondUrl
      ? blackDiamondUrl +
          "?RelayState=https://bd3.bdreporting.com/DataManagement/Manage%23/securityMaster/assets"
      : ""
  );

  return (
    <React.Fragment>
      <Iframe
        url={url}
        id="blackDiamondSecurityMasterFrame"
        width="100%"
        height="100%"
        display="initial"
        position="relative"
        className="iframe"
      />
    </React.Fragment>
  );
};
