import React from "react";

// Layouts
import DashboardLayout from "./layouts/Dashboard";

// Dashboard
import Dashboard from "./pages/dashboard";

// Pages
import Profile from "./pages/pages/Profile";
import Settings from "./pages/pages/Settings";
import { Clients } from "./pages/pages/clients/index";
import { HouseholdOverview } from "./pages/pages/household-overview/index";
import { CRMDashboard } from "./pages/pages/crm-dashboard";

import Projects from "./pages/pages/Projects";
import Invoice from "./pages/pages/Invoice";
import Pricing from "./pages/pages/Pricing";
import Tasks from "./pages/pages/Tasks";
import Chat from "./pages/pages/Chat";
import Blank from "./pages/pages/Blank";
import { BlackDiamond } from "./pages/pages/blackdiamond/BlackDiamond";
import { BlackDiamondSecurityMaster } from "./pages/pages/blackdiamond/BlackDiamondSecurityMaster";
import { BlackDiamondAccounts } from "./pages/pages/blackdiamond/BlackDiamondAccounts";
import { BlackDiamondPortfolios } from "./pages/pages/blackdiamond/BlackDiamondPortfolios";
import { BlackDiamondClientDashboard } from "./pages/pages/blackdiamond/BlackDiamondClientDashboard";
import { MoneyGuidePro } from "./pages/pages/moneyguidepro/MoneyGuidePro";
import { AdhesionLink } from "./pages/pages/adhesion/AdhesionLink";
import { BlackDiamondLink } from "./pages/pages/blackdiamond/BlackDiamondLink";
import { ElementsLink } from "./pages/pages/elements/ElementsLink";
import { MoneyGuideProLink } from "./pages/pages/moneyguidepro/MoneyGuideProLink";
import { ZephyrLink } from "./pages/pages/zephyr/ZephyrLink";

// Auth
import { ProtectedRoute } from "./components/auth/protected-route";

// UI components
import Alerts from "./pages/ui/Alerts";
import Buttons from "./pages/ui/Buttons";
import Cards from "./pages/ui/Cards";
import Carousel from "./pages/ui/Carousel";
import EmbedVideo from "./pages/ui/EmbedVideo";
import General from "./pages/ui/General";
import Grid from "./pages/ui/Grid";
import Modals from "./pages/ui/Modals";
import Offcanvas from "./pages/ui/Offcanvas";
import Tabs from "./pages/ui/Tabs";
import Typography from "./pages/ui/Typography";

// Icons
import Feather from "./pages/icons/Feather";
import FontAwesome from "./pages/icons/FontAwesome";

// Forms
import Layouts from "./pages/forms/Layouts";
import BasicInputs from "./pages/forms/BasicInputs";
import InputGroups from "./pages/forms/InputGroups";
import FloatingLabels from "./pages/forms/FloatingLabels";
import AdvancedInputs from "./pages/forms/AdvancedInputs";
import Formik from "./pages/forms/Formik";
import Editors from "./pages/forms/Editors";

// Tables
import Tables from "./pages/tables/Tables";
import Pagination from "./pages/tables/Pagination";
import ColumnSorting from "./pages/tables/ColumnSorting";
import ColumnFiltering from "./pages/tables/ColumnFiltering";
import RowExpanding from "./pages/tables/RowExpanding";
import RowSelection from "./pages/tables/RowSelection";

// Charts
import Chartjs from "./pages/charts/Chartjs";
import ApexCharts from "./pages/charts/ApexCharts";

// Notifications
import Notifications from "./pages/notifications/Notifications";

// Calendar
import Calendar from "./pages/calendar/Calendar";
import { FinancialPlanningDashboard } from "./pages/pages/financial-planning-dashboard";
import { ModelsAndTradeManagement } from "./pages/pages/models-trading";
import { ModelsAndTradeHoldings } from "./pages/pages/models-trade-holdings";
import { FirmHierarchy } from "./pages/pages/firm-hierarchy/FirmHierarchy";
import { FirmDetails } from "./pages/pages/firm-details/FirmDetails";
import { HouseholdImport } from "./pages/pages/household-import";
import { HouseholdLinking } from "./pages/pages/household-linking";
import { PendingHouseholds } from "./pages/pages/pending-households";
import { CreateHousehold } from "./pages/pages/households/create-household";

const routes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "/",
        element: <ProtectedRoute component={Dashboard} />,
      },
    ],
  },
  {
    path: "partners",
    element: <DashboardLayout />,
    children: [
      {
        path: "blank",
        element: <Blank />,
      },
      {
        path: "adhesion",
        element: <ProtectedRoute component={AdhesionLink} />,
      },
      {
        path: "blackdiamond",
        element: <ProtectedRoute component={BlackDiamondLink} />,
      },
      {
        path: "elements",
        element: <ProtectedRoute component={ElementsLink} />,
      },
      {
        path: "moneyguidepro",
        element: <ProtectedRoute component={MoneyGuideProLink} />,
      },
      {
        path: "zephyr",
        element: <ProtectedRoute component={ZephyrLink} />,
      },
    ],
  },
  {
    path: "pages",
    element: <DashboardLayout />,
    children: [
      {
        path: "blackdiamond/menu",
        element: <ProtectedRoute component={BlackDiamond} />,
      },
      {
        path: "blackdiamond/securitymaster",
        element: <ProtectedRoute component={BlackDiamondSecurityMaster} />,
      },
      {
        path: "blackdiamond/accounts",
        element: <ProtectedRoute component={BlackDiamondAccounts} />,
      },
      {
        path: "blackdiamond/portfolios",
        element: <ProtectedRoute component={BlackDiamondPortfolios} />,
      },
      {
        path: "blackdiamond/clientdashboard",
        element: <ProtectedRoute component={BlackDiamondClientDashboard} />,
      },
      {
        path: "moneyguidepro",
        element: <ProtectedRoute component={MoneyGuidePro} />,
      },
      {
        path: "profile",
        element: <ProtectedRoute component={Profile} />,
      },
      {
        path: "firms",
        element: <ProtectedRoute component={FirmHierarchy} />,
      },
      {
        path: "firms/:id",
        element: <ProtectedRoute component={FirmDetails} />,
      },
      {
        path: "household-import",
        element: <ProtectedRoute component={HouseholdImport} />,
      },
      {
        path: "pending-households",
        element: <ProtectedRoute component={PendingHouseholds} />,
      },
      {
        path: "household-linking/:householdId",
        element: <ProtectedRoute component={HouseholdLinking} />,
      },
      {
        path: "households/create",
        element: <ProtectedRoute component={CreateHousehold} />,
      },
      {
        path: "settings",
        element: <ProtectedRoute component={Settings} />,
      },
      {
        path: "clients",
        exact: true,
        element: <ProtectedRoute component={Clients} />,
      },
      {
        path: "clients/:id",
        element: <ProtectedRoute component={HouseholdOverview} />,
      },
      {
        path: "crm",
        element: <ProtectedRoute component={CRMDashboard} />,
      },
      {
        path: "financial-planning-dashboard",
        element: <ProtectedRoute component={FinancialPlanningDashboard} />,
      },
      {
        path: "projects",
        element: <ProtectedRoute component={Projects} />,
      },
      {
        path: "invoice",
        element: <ProtectedRoute component={Invoice} />,
      },
      {
        path: "pricing",
        element: <ProtectedRoute component={Pricing} />,
      },
      {
        path: "tasks",
        element: <ProtectedRoute component={Tasks} />,
      },
      {
        path: "chat",
        element: <ProtectedRoute component={Chat} />,
      },
      {
        path: "blank",
        element: <Blank />,
      },
      {
        path: "models-trade-management",
        exact: true,
        element: <ModelsAndTradeManagement />,
      },
      {
        path: "models-trade-management/:modelId/holdings",
        element: <ModelsAndTradeHoldings />,
      },
    ],
  },
  {
    path: "ui",
    element: <DashboardLayout />,
    children: [
      {
        path: "alerts",
        element: <Alerts />,
      },
      {
        path: "buttons",
        element: <Buttons />,
      },
      {
        path: "cards",
        element: <Cards />,
      },
      {
        path: "carousel",
        element: <Carousel />,
      },
      {
        path: "embed-video",
        element: <EmbedVideo />,
      },
      {
        path: "general",
        element: <General />,
      },
      {
        path: "grid",
        element: <Grid />,
      },
      {
        path: "modals",
        element: <Modals />,
      },
      {
        path: "tabs",
        element: <Tabs />,
      },
      {
        path: "offcanvas",
        element: <Offcanvas />,
      },
      {
        path: "typography",
        element: <Typography />,
      },
    ],
  },
  {
    path: "icons",
    element: <DashboardLayout />,
    children: [
      {
        path: "feather",
        element: <Feather />,
      },
      {
        path: "font-awesome",
        element: <FontAwesome />,
      },
    ],
  },
  {
    path: "forms",
    element: <DashboardLayout />,
    children: [
      {
        path: "layouts",
        element: <Layouts />,
      },
      {
        path: "basic-inputs",
        element: <BasicInputs />,
      },
      {
        path: "input-groups",
        element: <InputGroups />,
      },
      {
        path: "floating-labels",
        element: <FloatingLabels />,
      },
    ],
  },
  {
    path: "tables",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Tables />,
      },
    ],
  },
  {
    path: "form-plugins",
    element: <DashboardLayout />,
    children: [
      {
        path: "advanced-inputs",
        element: <AdvancedInputs />,
      },
      {
        path: "formik",
        element: <Formik />,
      },
      {
        path: "editors",
        element: <Editors />,
      },
    ],
  },
  {
    path: "advanced-tables",
    element: <DashboardLayout />,
    children: [
      {
        path: "pagination",
        element: <Pagination />,
      },
      {
        path: "column-sorting",
        element: <ColumnSorting />,
      },
      {
        path: "column-filtering",
        element: <ColumnFiltering />,
      },
      {
        path: "row-expanding",
        element: <RowExpanding />,
      },
      {
        path: "row-selection",
        element: <RowSelection />,
      },
    ],
  },
  {
    path: "charts",
    element: <DashboardLayout />,
    children: [
      {
        path: "chartjs",
        element: <Chartjs />,
      },
      {
        path: "apexcharts",
        element: <ApexCharts />,
      },
    ],
  },
  {
    path: "notifications",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Notifications />,
      },
    ],
  },
  {
    path: "calendar",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Calendar />,
      },
    ],
  },
];

export default routes;
