import React from "react";
import { Card, Table } from "react-bootstrap";
import { FinancialPlanClient } from "./hooks";
import { SelectableTableRow } from "../../../components/SelectableTableRow";

type ClientRowProps = {
  client: FinancialPlanClient;
  onClickClient(client: FinancialPlanClient): void;
  isSelected: boolean;
  index: number;
};

const ClientRow: React.FC<ClientRowProps> = ({
  client,
  onClickClient,
  isSelected,
}) => {
  const onClickRow = React.useCallback(() => {
    onClickClient(client);
  }, [client, onClickClient]);

  return (
    <SelectableTableRow isSelected={isSelected} onClick={onClickRow}>
      <td>{client.houseHoldName}</td>
      <td>{client.planCreated}</td>
      <td>{client.planUpdated}</td>
      <td>{client.probabilityOfSuccess}</td>
      <td>{client.openOpportunities}</td>
      <td>{client.amountOfOpportunity}</td>
    </SelectableTableRow>
  );
};

type ClientsTableProps = {
  clients: FinancialPlanClient[];
  selectedClient: FinancialPlanClient;
  onClickClient(client: FinancialPlanClient): void;
};

export const ClientsTable: React.FC<ClientsTableProps> = ({
  clients,
  selectedClient,
  onClickClient,
}) => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">Clients</Card.Title>
    </Card.Header>
    <Card.Body>
      <Table className="mb-0">
        <thead>
          <tr>
            <th>HouseHold Name</th>
            <th>Plan Created</th>
            <th>Plan Updated</th>
            <th>Probability of Success</th>
            <th>Open Opportunities</th>
            <th>$ Amount of Opportunity</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client, index) => (
            <ClientRow
              client={client}
              isSelected={selectedClient === client}
              onClickClient={onClickClient}
              index={index}
            />
          ))}
        </tbody>
      </Table>
    </Card.Body>
  </Card>
);
