import { Card, Table } from "react-bootstrap";

export const RepCodeList: React.FC<{}> = () => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">Rep Codes</Card.Title>
    </Card.Header>
    <Card.Body>
      <Table>
        <thead>
          <tr>
            <th>Rep Code</th>
            <th>Custodian</th>
            <th>Team Assignment</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ALIQ</td>
            <td>TD Ameritrade</td>
            <td>Steve Ankerstar</td>
          </tr>
        </tbody>
      </Table>
    </Card.Body>
  </Card>
);
