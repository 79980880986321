import React from "react";
import { Col, Row } from "react-bootstrap";
import { useOrganization } from "../../../contexts/ProfileContext";
import { useAuth0 } from "@auth0/auth0-react";

export const ZephyrLink: React.FC = () => {
  const organization = useOrganization();
  const { getIdTokenClaims } = useAuth0();
  const [ssoUrl, setSsoUrl] = React.useState<string | null>(null);
  React.useEffect(() => {
    (async () => {
      const claims = await getIdTokenClaims();
      if (claims && claims["zephyr_sso_url"]) {
        setSsoUrl(claims["zephyr_sso_url"]);
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h2>
          Zephyr
          <br />
          Research & Analytics
        </h2>
        <p>
          Fund and Market Research can take anywhere from a few minutes to a few
          hours. Advising firms usually have a dedicated research day and are
          forced to use the custodian tools when doing fund comparisons and due
          diligence. Zephyr provides advisors with a professional investment
          research platform to enhance firms’ offerings. This includes research
          on SMAs offered through the AdvizorStack platform.{" "}
        </p>
      </div>
      <div className="text-center">
        {organization && (
          <a
            href={`${ssoUrl}?organization=${organization}`}
            className="btn btn-primary w-75"
            target="_blank"
            rel="noreferrer noopener"
          >
            LAUNCH APP
          </a>
        )}
      </div>
      <Row className="mt-5 text-center">
        <Col md={4}>
          <h2>PROPOSAL GENERATION</h2>
          <p>
            Provide your clients with a comprehensive, yet easy to interpret
            proposal, that conveys your value add when comparing a current
            portfolio to your proposed portfolio.
          </p>
          <p>
            Customizable proposal tool that can add or subtract passages and
            graphs based on the prospective client you’re working with.
          </p>
        </Col>
        <Col md={4}>
          <h2>CLIENT REPORTING</h2>
          <p>
            Streamline the reporting process by utilizing a simple tool to build
            customized, engaging reports to meet the needs of your clients.
          </p>
          <p>
            Customizable report building allows you to create a report from
            scratch and save the template for future use.
          </p>
        </Col>
        <Col md={4}>
          <h2>PORTFOLIO ANALYTICS</h2>
          <p>
            Be able to import prospective client portfolios and compare them to
            a model portfolio directly on the Zephyr system. This information is
            saved for the lifetime of the platform and can be reused at any
            time.
          </p>
        </Col>
      </Row>
      <Row className="mt-5 text-center">
        <Col md={4}>
          <h2>MODELING</h2>
          <p>
            Estimate the probability of meeting future wealth goals utilizing
            configurable Monte Carlo simulation models.
          </p>
          <p>
            Be able to see model and/or individual position risk levels that
            would make a portfolio likely or unlikely for success.
          </p>
        </Col>
        <Col md={4}>
          <h2>MANAGER RESEARCH & ANALYSIS</h2>
          <p>
            Screen thousands of investment portfolio products with powerful
            sorting, filtering and statistical tools.
          </p>
          <p>
            Access to PSN and MorningStar research data are available for
            additional research capabilities.
          </p>
        </Col>
        <Col md={4}>
          <h2>ASSET ALLOCATION</h2>
          <p>
            Build portfolios using classic mean variance optimization or
            sophisticated Black-Litterman optimization. Advisors can create
            their own portfolios utilizing their own portfolio construction
            preferences.
          </p>
        </Col>
      </Row>
    </React.Fragment>
  );
};
