import React from "react";
import classNames from "classnames";

type SelectableTableRowProps = React.PropsWithChildren<
  {
    isSelected: boolean;
  } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  >
>;

export const SelectableTableRow: React.FC<SelectableTableRowProps> = ({
  className,
  isSelected,
  children,
  ...rest
}) => {
  const additionalClassNames = classNames(
    className,
    isSelected && "table-info"
  );
  return (
    <tr className={additionalClassNames} {...rest}>
      {children}
    </tr>
  );
};
