import React from "react";
import { Helmet } from "react-helmet-async";

import { Breadcrumb, Button, Card, Col, Container, Row } from "react-bootstrap";
import { AdvisorList } from "./AdvisorList";
import { RepCodeList } from "./RepCodeList";
import { Link } from "react-router-dom";
import { TeamList } from "./TeamList";

type SubPages = "rep-codes" | "advisors" | "teams";

const titleForPage = (page: SubPages) => {
  switch (page) {
    case "advisors":
      return "Firm Users";
    case "rep-codes":
      return "Rep Codes";
    case "teams":
      return "Teams";
  }
};

export const FirmDetails = () => {
  const [selectedPage, setSelectedPage] = React.useState<SubPages>("advisors");

  return (
    <React.Fragment>
      <Helmet title="Ankerstar Wealth" />
      <Container fluid className="p-0">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/pages/firms">Firms</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Ankerstar Wealth</Breadcrumb.Item>
          <Breadcrumb.Item active>{titleForPage(selectedPage)}</Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="h3 mb-3">Ankerstar Wealth</h1>
        <Row>
          <Col md={2}>
            <Card>
              <Card.Body>
                <Button
                  onClick={() => setSelectedPage("advisors")}
                  variant="link"
                >
                  Firm Users
                </Button>
                <Button onClick={() => setSelectedPage("teams")} variant="link">
                  Teams
                </Button>
                <Button
                  onClick={() => setSelectedPage("rep-codes")}
                  variant="link"
                >
                  Rep Codes
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={9}>
            {selectedPage === "advisors" && <AdvisorList />}
            {selectedPage === "rep-codes" && <RepCodeList />}
            {selectedPage === "teams" && <TeamList />}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
