import React from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";
import { FirmAssetAllocation } from "./FirmAssetAllocation";
import { TopModels } from "./TopModels";
import { RegionAllocation } from "./RegionAllocation";
import { MODELS_DATA, ModelInfo } from "./data";
import { ModelDetail } from "./ModelDetail";
export const ModelsAndTradeManagement = () => {
  const [selectedModel, setSelectedModel] = React.useState<ModelInfo>(
    MODELS_DATA[0]
  );
  const onSelectModel = React.useCallback(
    (model: ModelInfo) => {
      setSelectedModel(model);
    },
    [setSelectedModel]
  );
  return (
    <React.Fragment>
      <Helmet title="Models & Trades" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Models & Trade Management</h1>
        <Row>
          <Col md={8}>
            <Row>
              <Col md={6}>
                <FirmAssetAllocation />
              </Col>
              <Col md={6}>
                <RegionAllocation />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <TopModels
                  selectedModel={selectedModel}
                  onSelectModel={onSelectModel}
                  models={MODELS_DATA}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <ModelDetail model={selectedModel} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
