import React from "react";

import { Card, Col, Row } from "react-bootstrap";

type AlertsProps = {};

export const Alerts: React.FC<AlertsProps> = () => {
  return (
    <Card>
      <Card.Header>
        <Card.Title>Alerts</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <h3 className="mb-2">45</h3>
            <small className="mb-2">Plans with probability &lt; 80%</small>
          </Col>
          <Col>
            <h3 className="mb-2">13</h3>
            <small className="mb-2">clients retiring in next year</small>
          </Col>
          <Col>
            <h3 className="mb-2">4</h3>
            <small className="mb-2">clients retiring in next 5 years</small>
          </Col>
        </Row>
        <Row className="mt-5 mb-5">
          <Col>
            <h3 className="mb-2">11</h3>
            <small className="mb-2">
              plans not updated in more than 1 year
            </small>
          </Col>
          <Col>
            <h3 className="mb-2">16</h3>
            <small className="mb-2">open opportunities</small>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="mb-2">16</h3>
            <small className="mb-2">open opportunities &gt; 1 mo</small>
          </Col>
          <Col>
            <h3 className="mb-2">25</h3>
            <small className="mb-2">open opportunities &gt; 3 mo</small>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
