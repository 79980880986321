import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import usePalette from "../../../hooks/usePalette";
import { Card } from "react-bootstrap";

const data = [
  {
    Region: "North America",
    Percent: "36.60%",
  },
  {
    Region: "Europe",
    Percent: "9.80%",
  },
  {
    Region: "Asia",
    Percent: "10.50%",
  },
  {
    Region: "Latin America",
    Percent: "0.50%",
  },
  {
    Region: "Middle East",
    Percent: "0.50%",
  },
  {
    Region: "Aftrica",
    Percent: "0.20%",
  },
  {
    Region: "Not Classified / Fixed Income",
    Percent: "40.90%",
  },
];

export const RegionAllocation: React.FC<{}> = () => {
  const palette = usePalette();

  const [labels, amounts] = React.useMemo(() => {
    return [data.map((r) => r.Region), data.map((r) => parseFloat(r.Percent))];
  }, []);

  const options: ApexOptions = {
    dataLabels: {
      enabled: false,
    },
    labels,
    colors: [
      palette.primary,
      palette.success,
      palette.warning,
      palette.danger,
      palette.info,
    ],
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Region Allocation</Card.Title>
      </Card.Header>
      <Card.Body>
        <Chart options={options} series={amounts} type="donut" />
      </Card.Body>
    </Card>
  );
};
