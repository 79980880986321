import React from "react";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { store } from "./redux/store";

import "./i18n";
import routes from "./routes";

import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import ChartJsDefaults from "./utils/ChartJsDefaults";
import { ProfileProvider } from "./contexts/ProfileContext";

const App = () => {
  LogRocket.init("6mggzl/stacked-dashboard-demo");
  setupLogRocketReact(LogRocket);

  const content = useRoutes(routes);

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | AdvizorStack" defaultTitle="AdvizorStack" />
      <Provider store={store}>
        <ProfileProvider>
          <ThemeProvider>
            <SidebarProvider>
              <LayoutProvider>
                <ChartJsDefaults />
                {content}
              </LayoutProvider>
            </SidebarProvider>
          </ThemeProvider>
        </ProfileProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
