import React from "react";
import classNames from "classnames";

import avatar3 from "../../../assets/img/avatars/avatar-3.jpg";
import { Client } from "./types";
import { Badge, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

type ClientRowProps = {
  client: Client;
  onClickClient(client: Client): void;
  isSelected: boolean;
  index: number;
};

const ACCOUNTS = [
  {
    "Household Name": "Petyr Baelish",
    "Account Name": "Baelish, Petry - Individual",
    "Account Type": "Individual",
    "Account Number": 900000018,
    AUM: "1,020.52",
  },
  {
    "Household Name": "Petyr Baelish",
    "Account Name": "Baelish, Petyr - 401k",
    "Account Type": "401k",
    "Account Number": 900000017,
    AUM: "1,024.62",
  },
  {
    "Household Name": "Petyr Baelish",
    "Account Name": "Baelish, Petyr - IRA",
    "Account Type": "IRA",
    "Account Number": 900000005,
    AUM: "67,265.05",
  },
  {
    "Household Name": "Robert Baratheon",
    "Account Name": "Baratheon, Robert",
    "Account Type": "Individual",
    "Account Number": 900000006,
    AUM: "145,731.99",
  },
  {
    "Household Name": "Tim Gitchel",
    "Account Name": "Gitchel, Tim - Individual",
    "Account Type": "Individual",
    "Account Number": "BDTEST0003",
    AUM: "17,557,528.06",
  },
  {
    "Household Name": "Tim Gitchel",
    "Account Name": "Gitchel, Tim - IRA",
    "Account Type": "IRA",
    "Account Number": "BDTEST0002",
    AUM: "46,426.49",
  },
  {
    "Household Name": "Tim Gitchel",
    "Account Name": "Gitchel, Tim - LLC",
    "Account Type": "Corporate",
    "Account Number": "BDTEST0001",
    AUM: "2,360,523.03",
  },
  {
    "Household Name": "Peter Lannister",
    "Account Name": "Lannister, Peter - Individual",
    "Account Type": "Individual",
    "Account Number": 900000100,
    AUM: "4,139,700.00",
  },
  {
    "Household Name": "David Lee",
    "Account Name": "Lee, David - Trust",
    "Account Type": "Trust",
    "Account Number": "5678DEMO",
    AUM: "6,389,736.92",
  },
  {
    "Household Name": "Travis Porter",
    "Account Name": "Porter, Travis Roth IRA",
    "Account Type": "Roth IRA",
    "Account Number": "3412DEMO",
    AUM: "50,130.34",
  },
  {
    "Household Name": "Travis Porter",
    "Account Name": "Porter, Travis Trust",
    "Account Type": "Trust",
    "Account Number": "4123DEMO",
    AUM: "4,814,791.07",
  },
  {
    "Household Name": "Dany Targaryen",
    "Account Name": "Targaryen, Dany",
    "Account Type": "Individual",
    "Account Number": 900000003,
    AUM: "28,408.33",
  },
  {
    "Household Name": "Brieene Tarth",
    "Account Name": "Tarth, Brieene",
    "Account Type": "Individual",
    "Account Number": 900000004,
    AUM: "154,338.88",
  },
  {
    "Household Name": "Queen Ursula",
    "Account Name": "Ursula Trad IRA",
    "Account Type": "IRA",
    "Account Number": 23456788,
    AUM: "5,090.37",
  },
  {
    "Household Name": "Queen Ursula",
    "Account Name": "Ursula Under The Sea Trust",
    "Account Type": "Trust",
    "Account Number": 23456789,
    AUM: "9,449,886.54",
  },
];

export const ClientRow: React.FC<ClientRowProps> = ({
  client,
  onClickClient,
  isSelected,
  index,
}) => {
  const onClickRow = React.useCallback(() => {
    onClickClient(client);
  }, [client, onClickClient]);

  const className = classNames(isSelected && "table-info");

  const isActive = client.clientStatus === "Active";
  const [selectedHousehold, setSelectedHousehold] = React.useState("");
  const [showAccounts, setShowAccounts] = React.useState(false);

  return (
    <>
      <tr className={className} onClick={onClickRow}>
        <td>
          <img
            src={avatar3}
            width="32"
            height="32"
            className="rounded-circle my-n1"
            alt="Avatar"
          />
        </td>
        <td>
          {client.houseHoldName}
          <div>
            <Badge className="me-2" bg={isActive ? "primary" : "warning"}>
              {isActive ? "Active" : "Inactive"}
            </Badge>
          </div>
        </td>
        <td>${client.totalAum}</td>
        <td>
          <Button
            onClick={() => {
              setShowAccounts(!showAccounts);
              setSelectedHousehold(client.houseHoldName);
            }}
            color="link"
          >
            {client.numberOfAccounts}
          </Button>
        </td>
        <td>{client.ytdPerformance}</td>
        <td>{client.target}</td>
        <td>
          <Link to={`/pages/clients/${index}`} className="btn btn-primary">
            Overview
          </Link>
        </td>
      </tr>
      {showAccounts && (
        <tr>
          <td />
          <td colSpan={6}>
            <Table>
              <thead>
                <th>Account Number</th>
                <th>Account Name</th>
                <th>Account Type</th>
                <th>AUM</th>
              </thead>
              {ACCOUNTS.filter(
                (account) => account["Household Name"] === selectedHousehold
              ).map((account) => (
                <tr>
                  <td>{account["Account Number"]}</td>
                  <td>{account["Account Name"]}</td>
                  <td>{account["Account Type"]}</td>
                  <td>{account["AUM"]}</td>
                </tr>
              ))}
            </Table>
          </td>
        </tr>
      )}
    </>
  );
};
