import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import usePalette from "../../../hooks/usePalette";

export const PieChart = () => {
  const palette = usePalette();

  const data = [64, 36];

  const options: ApexOptions = {
    dataLabels: {
      enabled: false,
    },
    labels: ["High Value Held Away", "Held Away"],
    colors: [
      palette.primary,
      palette.success,
      palette.warning,
      palette.danger,
      palette.info,
    ],
  };

  return <Chart options={options} series={data} type="donut" />;
};
