import { Table } from "react-bootstrap";

export const PortfolioStats = () => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Portfolio Statistics</th>
          <th>Return</th>
          <th>Cumulative Return</th>
          <th>Standard Deviation</th>
          <th>Excess Return</th>
          <th>Cumulative Excess Return</th>
          <th>Information Ratio</th>
          <th>R-Squared</th>
          <th>Tracking Error</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Queen Ursula Portfolio</td>
          <td>7.48%</td>
          <td>110.81%</td>
          <td>10.15</td>
          <td>0.19%</td>
          <td>3.90%</td>
          <td>0.10</td>
          <td>0.96</td>
          <td>1.98</td>
        </tr>
        <tr>
          <td>60/40 Model</td>
          <td>7.29%</td>
          <td>106.90%</td>
          <td>9.61</td>
          <td>0%</td>
          <td>0%</td>
          <td>N/A</td>
          <td>100.00</td>
          <td>0.00</td>
        </tr>
      </tbody>
    </Table>
  );
};
