import { UserRole } from "./user";

type BaseSidebarItemsType = {
  title: string;
  icon: React.FC<any>;
  children?: SidebarItemsType[];
  badge?: string;
  roleWhitelist?: UserRole[];
  roleBlacklist?: UserRole[];
};

export type HrefSidebarItemsType = {
  href: string;
} & BaseSidebarItemsType;

export type ExternalLinkSidebarItemsType = {
  externalLink: string;
} & BaseSidebarItemsType;

export type SidebarItemsType =
  | HrefSidebarItemsType
  | ExternalLinkSidebarItemsType;

export const isHrefSidebarItemsType = (
  props: SidebarItemsType
): props is HrefSidebarItemsType => {
  return !!(props as HrefSidebarItemsType).href;
};
