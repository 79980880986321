import React from "react";
import { Button, Table } from "react-bootstrap";
import { ExternalRef } from "../../../models/household";
import { Client } from "../clients/types";
import {
  ExternalRefSyncStatus,
  SaveExternalRefsFunction,
} from "../../../models/externalRef";

type LinkedRefSectionProps = {
  linkedRef: ExternalRef;
  name: string;
  client: Client;
  saveExternalRef: SaveExternalRefsFunction;
};

export const LinkedRefSection: React.FC<LinkedRefSectionProps> = ({
  name,
  linkedRef,
  client,
  saveExternalRef,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const saveLink = React.useCallback(
    async (status: ExternalRefSyncStatus) => {
      setLoading(true);
      await saveExternalRef([
        {
          id: linkedRef.id,
          externalId: linkedRef.externalId,
          name: linkedRef.name,
          syncStatus: status,
        },
      ]);
      setLoading(false);
      setSaved(true);
    },
    [saveExternalRef, setLoading, setSaved, linkedRef]
  );

  const approveLink = React.useCallback(() => {
    saveLink("Approved");
  }, [saveLink]);

  const ignoreLink = React.useCallback(async () => {
    saveLink("Ignore");
  }, [saveLink]);

  const unlink = React.useCallback(async () => {
    saveLink("Unlinked");
  }, [saveLink]);

  if (linkedRef.syncStatus === "Pending") {
    return (
      <>
        <Table size="sm" className="my-2">
          <tbody>
            <tr>
              <th>{name} ID</th>
              <td colSpan={2}>{linkedRef.externalId}</td>
            </tr>
            <tr>
              <th>{name} Household Name</th>
              <td>{client.houseHoldName}</td>
              <td>
                {/* <Form.Group className="mb-2">
                  <Form.Check label="Ignore" type="checkbox" />
                </Form.Group> */}
              </td>
            </tr>
            <tr>
              <th>
                <p className="mb-0">Address</p>
              </th>
              <td>{client.address}</td>
              <td>
                {/* <Form.Group className="mb-2">
                  <Form.Check label="Ignore" type="checkbox" />
                </Form.Group> */}
              </td>
            </tr>
          </tbody>
        </Table>
        {!loading && !saved && (
          <>
            <Button onClick={approveLink} className="mt-3" variant="success">
              Approve Link
            </Button>
            <Button onClick={ignoreLink} className="mt-3 ms-3" variant="danger">
              Ignore Link
            </Button>
          </>
        )}
        {loading && <p>Saving...</p>}
        {saved && <p className="text-primary">Saved!</p>}
      </>
    );
  }

  return (
    <>
      <Table size="sm" className="my-2">
        <tbody>
          <tr>
            <th>{name} ID</th>
            <td colSpan={2}>{linkedRef.externalId}</td>
          </tr>
          <tr>
            <th>{name} Household Name</th>
            <td colSpan={2}>{linkedRef.name}</td>
          </tr>
        </tbody>
      </Table>
      {!loading && !saved && (
        <Button onClick={unlink} className="mt-3" variant="danger">
          Unlink
        </Button>
      )}
      {loading && <p>Saving...</p>}
      {saved && <p className="text-primary">Saved!</p>}
    </>
  );
};
