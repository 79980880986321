import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import usePalette from "../../../../hooks/usePalette";
import { formatDate } from "../../../../utils/date-formatting";
import data from "./aum-chart.json";

const formatDateForLabel = (date: Date) => {
  return formatDate(date, "m/d/yy");
};

export const AUMChart = () => {
  const palette = usePalette();

  const options: ApexOptions = {
    dataLabels: {
      enabled: false,
    },
    labels: [""],
    colors: [
      palette.primary,
      palette.success,
      palette.warning,
      palette.danger,
      palette.info,
    ],
    xaxis: {
      categories: data.map((r) => formatDateForLabel(new Date(r.Date))),
    },
  };

  const series: ApexOptions["series"] = [
    {
      name: "AUM",
      data: data.map((r) => parseFloat(r.Value)),
    },
  ];

  return <Chart height={300} options={options} series={series} type="bar" />;
};
