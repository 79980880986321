import { Card, Row, Col } from "react-bootstrap";

const AtAGlanceState: React.FC<{
  stat: string | number;
  label: string;
  color: string;
}> = ({ stat, label, color }) => {
  return (
    <Col>
      <h1 className={`text-${color}`}>{stat}</h1>
      <small className={`text-${color}`}>{label}</small>
    </Col>
  );
};

export const AtAGlanceRow = () => {
  return (
    <Card>
      <Card.Body>
        <Row>
          <AtAGlanceState
            stat={3}
            label="Accounts out of tolerance"
            color="danger"
          />
          <AtAGlanceState stat={10} label="Open tasks" color="warning" />
          <AtAGlanceState
            stat={10}
            label="Households without a financial plan"
            color="warning"
          />
          <AtAGlanceState
            stat={4}
            label="Financial plans not updated in the last year"
            color="warning"
          />
          <AtAGlanceState
            stat="7"
            label="Accounts portfolio > client risk"
            color="warning"
          />
          <AtAGlanceState
            stat={"<70%"}
            label="Financial plans with <70% success"
            color="success"
          />
          <AtAGlanceState
            stat="0"
            label="Accounts without target"
            color="success"
          />
        </Row>
      </Card.Body>
    </Card>
  );
};
