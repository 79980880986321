import { useAuth0 } from "@auth0/auth0-react";
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

type IProfileContext = {
  organization?: string;
};

export const ProfileContext = createContext<IProfileContext>({});

export const useOrganization = () => {
  return useContext(ProfileContext).organization;
};

export const ProfileProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const [organization, setOrganization] = useState<string | undefined>();
  useEffect(() => {
    const getOrg = async () => {
      const claims = await getIdTokenClaims();
      const orgId = claims?.org_id;
      setOrganization(orgId);
    };

    if (isAuthenticated) {
      getOrg();
    }
  }, [getIdTokenClaims, isAuthenticated]);

  return (
    <ProfileContext.Provider value={{ organization }}>
      {children}
    </ProfileContext.Provider>
  );
};
