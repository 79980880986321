import { Card, Col, Row, Table } from "react-bootstrap";

import avatar3 from "../../../assets/img/avatars/avatar-3.jpg";
import { ModelInfo } from "./data";

type ModelDetailProps = {
  model: ModelInfo;
};

export const ModelDetail: React.FC<ModelDetailProps> = ({ model }) => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">{model.modelName}</Card.Title>
    </Card.Header>
    <Card.Body>
      <Row className="g-0">
        <Col sm="3" xl="12" className="col-xxl-3 text-center">
          <img
            src={avatar3}
            width="64"
            height="64"
            className="rounded-circle mt-2"
            alt={model.modelName}
          />
        </Col>
        <Col sm="9" xl="12" className="col-xxl-9">
          <strong>Notes</strong>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </Col>
      </Row>

      <Table size="sm" className="my-2">
        <tbody>
          <tr>
            <th>Benchmark</th>
            <td>{model.benchmark}</td>
          </tr>
          <tr>
            <th>Assets In Model</th>
            <td>{model.assets}</td>
          </tr>
          <tr>
            <th>Number of Accounts</th>
            <td>{model.numberOfAccounts}</td>
          </tr>
          <tr>
            <th>Number of up months</th>
            <td>{model.up}</td>
          </tr>
          <tr>
            <th>Number of down months</th>
            <td>{model.down}</td>
          </tr>
        </tbody>
      </Table>
      <Row className="mt-4 text-center">
        <Col xs={4}>
          <h3 className="mb-2">{model.upCapture}</h3>
          <small className="mb-2">Up Capture %</small>
        </Col>
        <Col xs={4}>
          <h3 className="mb-2">{model.downCapture}</h3>
          <small className="mb-2">Down Capture %</small>
        </Col>
        <Col xs={4}>
          <h3 className="mb-2">{model.painIndex}</h3>
          <small className="mb-2">Pain Index</small>
        </Col>
      </Row>
    </Card.Body>
  </Card>
);
