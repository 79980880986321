import React from "react";
import { Card, Table } from "react-bootstrap";
import { Client } from "./types";
import { ClientRow } from "./ClientRow";
import { Link } from "react-router-dom";

type ClientsListProps = {
  clients: Client[];
  selectedClient: Client;
  onClickClient(client: Client): void;
};

export const ClientsList: React.FC<ClientsListProps> = ({
  clients,
  selectedClient,
  onClickClient,
}) => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">Clients</Card.Title>
    </Card.Header>
    <Card.Body>
      <Table className="mb-0">
        <thead>
          <tr>
            <th></th>
            <th>Household</th>
            <th>Total AUM</th>
            <th># Accounts</th>
            <th>YTD Performance</th>
            <th>Target</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client, index) => (
            <ClientRow
              client={client}
              isSelected={selectedClient === client}
              onClickClient={onClickClient}
              index={index}
            />
          ))}
        </tbody>
      </Table>
      <Link to="/pages/households/create" className="btn btn-primary mt-4">
        Create
      </Link>
    </Card.Body>
  </Card>
);
