import { ApiResponse, useApi } from "../services/api";

export type ExternalRefSyncStatus =
  | "Pending"
  | "Unlinked"
  | "Approved"
  | "Ignore"
  | "Push";

export interface ExternalRefDto {
  id?: number;
  externalId?: string;
  name?: string;
  type?:
    | "Account"
    | "Advisor"
    | "AllocationTarget"
    | "AllocationTargetComponent"
    | "Contact"
    | "CustomValue"
    | "Event"
    | "Household"
    | "Note"
    | "Opportunity"
    | "Organization"
    | "Pipeline"
    | "PipelineStage"
    | "Portfolio"
    | "PortfolioGroup"
    | "SecurityMaster";
  startDate?: string;
  endDate?: string;
  inboundProperties?: string;
  outboundProperties?: string;
  syncStatus?: ExternalRefSyncStatus;
  createdAt?: string;
  updatedAt?: string;
  accountId?: number;
  advisorId?: number;
  allocationTargetComponentId?: number;
  allocationTargetId?: number;
  contactId?: number;
  customValueId?: number;
  eventId?: number;
  householdId?: number;
  noteId?: number;
  opportunityId?: number;
  pipelineId?: number;
  pipelineStageId?: number;
  portfolioGroupId?: number;
  portfolioId?: number;
  securityMasterId?: number;
}

export type SaveExternalRefsFunction = (
  body: ExternalRefDto[]
) => Promise<ApiResponse<unknown>>;
export const useSaveExternalRefs = () => {
  return useApi("ExternalRefs", "POST") as SaveExternalRefsFunction;
};
