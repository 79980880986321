import React from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";
import { UpcomingDates } from "./UpcomingDates";
import { ClientTag, CRMClient, Opportunity, UpcomingDate } from "./types";
import { Opportunities } from "./Opportunities";
import { Tags } from "./Tags";
import { ClientsTable } from "./ClientsTable";
import { useClientData } from "./hooks";
import { ClientDetail } from "./ClientDetail";
import { Alerts } from "./Alerts";

const DATE_LABELS: UpcomingDate[] = [
  {
    label: "William Jones",
    dateLabel: "Birthday: May 19",
  },
  {
    label: "William Jones",
    dateLabel: "Birthday: May 19",
  },
  {
    label: "William Jones",
    dateLabel: "Birthday: May 19",
  },
];

const OPPS: Opportunity[] = [
  {
    label: "William Jones",
    targetClose: "June 1st",
  },
  {
    label: "Jones William",
    targetClose: "June 12th",
  },
];

const TAGS: ClientTag[] = [
  {
    label: "401k",
  },
  {
    label: "403(b)",
  },
  {
    label: "Birthday card",
  },
  {
    label: "Drip campaign",
  },
];

export const CRMDashboard = () => {
  const clients = useClientData();
  const [selectedClient, setSelectedClient] = React.useState<CRMClient>(
    clients[0]
  );
  const onClickClient = React.useCallback(
    (client: CRMClient) => {
      setSelectedClient(client);
    },
    [setSelectedClient]
  );

  return (
    <React.Fragment>
      <Helmet title="CRM Dashboard" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Clients</h1>
        <Row>
          <Col md={3}>
            <UpcomingDates dates={DATE_LABELS} />
          </Col>
          <Col md={3}>
            <Opportunities opps={OPPS} />
          </Col>
          <Col md={3}>
            <Tags tags={TAGS} />
          </Col>
          <Col md={3}>
            <Alerts />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <ClientsTable
              selectedClient={selectedClient}
              onClickClient={onClickClient}
              clients={clients}
            />
          </Col>
          <Col>
            <ClientDetail client={selectedClient} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
