import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";

import Header from "./Header";
import Appointments from "./Appointments";
import Calendar from "./Calendar";
import Feed from "./Feed";
import Projects from "./Projects";
import Statistics from "./Statistics";
import WatchList from "./WatchList";

const Default = () => (
  <React.Fragment>
    <Helmet title="Dashboard" />
    <Container fluid className="p-0">
      <Header />
      <Statistics />
      <Row>
        <Col lg="12" xl="12" className="d-flex">
          <WatchList />
        </Col>
      </Row>
      <Row>
        <Col lg="12" xl="12" className="d-flex">
          <Calendar />
        </Col>
      </Row>
      <Row>
        <Col lg="6" xl="6" className="d-flex">
          <Feed />
        </Col>
        <Col lg="6" xl="6" className="d-flex">
          <Appointments />
        </Col>
      </Row>
      <Projects />
    </Container>
  </React.Fragment>
);

export default Default;
