import React from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { ExternalRef, Household } from "../../../models/household";
import { Client } from "../clients/types";
import {
  ExternalRefSyncStatus,
  SaveExternalRefsFunction,
} from "../../../models/externalRef";
import { EntitySearch } from "./EntitySearch";

type UnlinkedRefSectionProps = {
  name: string;
  client: Client;
  household: Household;
  allRefs: ExternalRef[] | null;
  saveExternalRef: SaveExternalRefsFunction;
};

export const UnlinkedRefSection: React.FC<UnlinkedRefSectionProps> = ({
  name,
  client,
  allRefs,
  saveExternalRef,
  household,
}) => {
  const [selectedEntityToLink, setSelectedEntityToLink] =
    React.useState<ExternalRef | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const saveLink = React.useCallback(
    async (status: ExternalRefSyncStatus) => {
      if (!selectedEntityToLink) return;

      setLoading(true);
      await saveExternalRef([
        {
          id: selectedEntityToLink.id,
          externalId: selectedEntityToLink.externalId,
          name: selectedEntityToLink.name,
          syncStatus: status,
          householdId: household.id,
        },
      ]);
      setLoading(false);
      setSaved(true);
    },
    [saveExternalRef, household, selectedEntityToLink]
  );

  const linkWithExisting = React.useCallback(() => {
    saveLink("Approved");
  }, [saveLink]);

  const pushLink = React.useCallback(async () => {
    setLoading(true);
    await saveExternalRef([
      {
        name: client.houseHoldName,
        syncStatus: "Push",
        outboundProperties: JSON.stringify(client),
      },
    ]);
    setLoading(false);
    setSaved(true);
  }, [client, setLoading, setSaved, saveExternalRef]);

  if (selectedEntityToLink) {
    return (
      <>
        <Table size="sm" className="my-2">
          <tbody>
            <tr>
              <th>{name} ID</th>
              <td colSpan={2}>{selectedEntityToLink.externalId}</td>
            </tr>
            <tr>
              <th>{name} Household Name</th>
              <td colSpan={2}>{selectedEntityToLink.name}</td>
            </tr>
          </tbody>
        </Table>
        {!loading && !saved && (
          <Button onClick={linkWithExisting} className="mt-3" variant="primary">
            Link
          </Button>
        )}
        {loading && <p>Saving...</p>}
        {saved && <p className="text-primary">Saved!</p>}
      </>
    );
  }

  return (
    <Row>
      <Col md={4}>
        <h5>Push To {name}</h5>
        {!loading && !saved && <Button onClick={pushLink}>Create</Button>}
        {loading && <p className="text-primary">Saving...</p>}
        {saved && <p className="text-success">Saved!</p>}
        <p className="mt-2">
          AdvizorStack can push your data for <b>{client.houseHoldName}</b> to{" "}
          {name}
        </p>
      </Col>
      {!saved && (
        <Col md={8}>
          <h5>Link Existing {name} Household</h5>
          <EntitySearch<ExternalRef>
            onSelect={setSelectedEntityToLink}
            refs={allRefs}
          />
        </Col>
      )}
    </Row>
  );
};
