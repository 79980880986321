import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import usePalette from "../../../../hooks/usePalette";

export const AssetAllocation = () => {
  const palette = usePalette();

  const data = [84, 16];

  const options: ApexOptions = {
    dataLabels: {
      enabled: false,
    },
    labels: ["Equities", "Bonds"],
    colors: [
      palette.primary,
      palette.success,
      palette.warning,
      palette.danger,
      palette.info,
    ],
  };

  return <Chart height={300} options={options} series={data} type="donut" />;
};
