import { Badge, Card, Col, Row, Table } from "react-bootstrap";

import avatar3 from "../../../assets/img/avatars/avatar-3.jpg";
import { Client } from "./types";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";

type FlagBadgeProps = {
  value: boolean;
  trueText: string;
  falseText: string;
};

const FlagBadge: React.FC<FlagBadgeProps> = ({
  value,
  trueText,
  falseText,
}) => {
  return (
    <Badge className="me-2" bg={value ? "danger" : "info"}>
      {value ? trueText : falseText}
    </Badge>
  );
};

type ClientDetailProps = {
  client: Client;
  index: number;
};

export const ClientDetail: React.FC<ClientDetailProps> = ({
  index,
  client,
}) => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">{client.houseHoldName}</Card.Title>
    </Card.Header>
    <Card.Body>
      <Row className="g-0">
        <Col sm="3" xl="12" className="col-xxl-3 text-center">
          <img
            src={avatar3}
            width="64"
            height="64"
            className="rounded-circle mt-2"
            alt={client.houseHoldName}
          />
        </Col>
        <Col sm="9" xl="12" className="col-xxl-9">
          <strong>Notes</strong>
          <p>
            Mrs. Urusla is a highly successful entrepreneur and business owner
            seeking comprehensive financial services.
          </p>
        </Col>
      </Row>

      <div className="mb-4">
        <FlagBadge
          value={client.openTasks}
          trueText="Open Tasks"
          falseText="No open tasks"
        />
        <FlagBadge
          value={client.openOppourtunities}
          trueText="Open Oppourtunities"
          falseText="No Open Oppourtunities"
        />
        <FlagBadge
          value={client.accountsOutOfTolerance}
          trueText="Accounts in Tolerance"
          falseText="Accounts Out of Tolerance"
        />
        <FlagBadge
          value={!client.portfolioRiskGreaterThanRtq}
          trueText="Portfolio Risk within RTQ"
          falseText="Portfolio Risk Greater than RTQ"
        />
      </div>

      <Table size="sm" className="my-2">
        <tbody>
          <tr>
            <th>Address</th>
            <td>{client.address}</td>
          </tr>
          <tr>
            <th>Last Activity</th>
            <td>{client.lastActivityCrm}</td>
          </tr>
          <tr>
            <th>Contact Info</th>
            <td>{client.contactInfo || "n/a"}</td>
          </tr>
          <tr>
            <th>Number of Accounts</th>
            <td>{client.numberOfAccounts}</td>
          </tr>
          <tr>
            <th>% of Total Business</th>
            <td>{client.percentageOfTotalBusiness}</td>
          </tr>
          <tr>
            <th>Fees</th>
            <td>{client.fees}</td>
          </tr>
          <tr>
            <th>% of Fees</th>
            <td>{client.ofFees}</td>
          </tr>
        </tbody>
      </Table>

      <Row className="mt-4 text-center">
        <Col xs={4}>
          <h3 className="mb-2">{client.portfolioRiskScore}</h3>
          <small className="mb-2">Portfolio Risk Score</small>
        </Col>
        <Col xs={4}>
          <h3 className="mb-2">{client.riskScore}</h3>
          <small className="mb-2">Risk Score</small>
        </Col>
        <Col xs={4}>
          <h3 className="mb-2">{client.probabilityOfSuccess}</h3>
          <small className="mb-2">Probability of Success</small>
        </Col>
      </Row>

      <hr />

      <div className="mt-3 mb-3">
        <h4>Parnter Apps Status</h4>
        <Row className="mt-4">
          <Col md={6}>
            <div className="d-flex align-items-center">
              <Badge bg="success" className="rounded-circle me-2">
                <Icon.Check size={14} />
              </Badge>
              <span className="text-muted">Black Diamond</span>
            </div>
          </Col>
          <Col md={6}>
            <div className="d-flex align-items-center">
              <Badge bg="danger" className="rounded-circle me-2">
                <Icon.X size={14} />
              </Badge>
              <span className="text-muted">MoneyGuide</span>
            </div>
          </Col>
        </Row>
        <Link
          to={`/pages/household-linking/${index}`}
          className="btn btn-primary mt-4"
        >
          <Icon.Link size={12} className="me-2" />
          Manage Partner App Linking
        </Link>
      </div>

      <hr />

      <strong>Activity</strong>

      <ul className="timeline mt-2">
        <li className="timeline-item">
          <strong>Call</strong>
          <span className="float-end text-muted text-sm">2023-04-02 2P</span>
          <p>Call for client to perform a distribution from IRA Account</p>
        </li>
        <li className="timeline-item">
          <strong>Email</strong>
          <span className="float-end text-muted text-sm">2023-03-29 9A</span>
          <p>Email to touch base on clients investments</p>
        </li>
        <li className="timeline-item">
          <strong>Email</strong>
          <span className="float-end text-muted text-sm">2023-03-18 10A</span>
          <p>
            Sent a follow-up email to client regarding the distribution from
            their IRA account
          </p>
        </li>
        <li className="timeline-item">
          <strong>Task</strong>
          <span className="float-end text-muted text-sm">2023-02-09 8A</span>
          <p>
            Made a note in the CRM system to schedule a call with the client on
            the following day.
          </p>
        </li>
        <li className="timeline-item">
          <strong>Call</strong>
          <span className="float-end text-muted text-sm">2023-01-27 11A</span>
          <p>
            Contacted the client to discuss the status of the distribution
            request.
          </p>
        </li>
      </ul>
    </Card.Body>
  </Card>
);
