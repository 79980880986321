import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import usePalette from "../../../hooks/usePalette";
import { Card } from "react-bootstrap";

const data = [
  {
    Asset: "Equity",
    Allocation: "51.41",
  },
  {
    Asset: "Fixed Income",
    Allocation: "41.37",
  },
  {
    Asset: "Alternatives",
    Allocation: "4.31",
  },
  {
    Asset: "Cash",
    Allocation: "2.91",
  },
];

export const FirmAssetAllocation: React.FC<{}> = () => {
  const palette = usePalette();

  const [labels, amounts] = React.useMemo(() => {
    return [data.map((r) => r.Asset), data.map((r) => Number(r.Allocation))];
  }, []);

  const options: ApexOptions = {
    dataLabels: {
      enabled: false,
    },
    labels,
    colors: [
      palette.primary,
      palette.success,
      palette.warning,
      palette.danger,
      palette.info,
    ],
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Firm Asset Allocation</Card.Title>
      </Card.Header>
      <Card.Body>
        <Chart options={options} series={amounts} type="donut" />
      </Card.Body>
    </Card>
  );
};
