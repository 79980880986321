import React from "react";
import { Helmet } from "react-helmet-async";

import { Col, Container, Row } from "react-bootstrap";
import { ClientDetail } from "./ClientDetail";
import { ClientsList } from "./ClientList";
import { useClientData } from "./hooks";
import { Client } from "./types";
import { AtAGlanceRow } from "./AtAGlanceRow";

export const Clients = () => {
  const clients = useClientData();
  const [selectedClientIndex, setSelectedClientIndex] =
    React.useState<number>(0);
  const onClickClient = React.useCallback(
    (client: Client) => {
      setSelectedClientIndex(clients.findIndex((c) => c === client));
    },
    [setSelectedClientIndex, clients]
  );

  return (
    <React.Fragment>
      <Helmet title="Clients" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Clients</h1>
        <AtAGlanceRow />
        <Row>
          <Col xl="8">
            <ClientsList
              selectedClient={clients[selectedClientIndex]}
              onClickClient={onClickClient}
              clients={clients}
            />
          </Col>
          <Col xl="4">
            <ClientDetail
              index={selectedClientIndex}
              client={clients[selectedClientIndex]}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
