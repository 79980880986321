import React from "react";

import { Card, Dropdown } from "react-bootstrap";

import { MoreHorizontal } from "react-feather";

const Appointments = () => (
  <Card className="flex-fill w-100">
    <Card.Header>
      <div className="card-actions float-end">
        <Dropdown align="end">
          <Dropdown.Toggle as="a" bsPrefix="-">
            <MoreHorizontal />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>Action</Dropdown.Item>
            <Dropdown.Item>Another Action</Dropdown.Item>
            <Dropdown.Item>Something else here</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Card.Title className="mb-0">Appointments</Card.Title>
    </Card.Header>
    <Card.Body className="d-flex">
      <ul className="timeline">
        <li className="timeline-item">
          <strong>Meeting with Mr. Johnson</strong>
          <span className="float-end text-muted text-sm">30m ago</span>
          <p>
            I met with Mr. Johnson, a client seeking financial advice. We
            discussed his investment portfolio and goals, and I provided
            tailored recommendations, addressing his concerns and answering
            questions. It was a valuable session focused on guiding him towards
            informed financial decisions.
          </p>
        </li>
        <li className="timeline-item">
          <strong>Market Insights Webinar</strong>
          <span className="float-end text-muted text-sm">2d ago</span>
          <p>
            I attended a webinar providing valuable market insights for
            financial advisors. It covered trends, forecasts, and investment
            strategies, led by industry experts. The webinar kept me updated and
            informed to better serve my clients.
          </p>
        </li>
        <li className="timeline-item">
          <strong>Monthly Team Meeting</strong>
          <span className="float-end text-muted text-sm">6d ago</span>
          <p className="mb-0">
            Our financial advisory team held our monthly meeting. We
            collaborated, shared updates, and discussed strategic initiatives.
            We reviewed market trends, client performance, and upcoming
            regulations, fostering a collaborative environment for client
            solutions.
          </p>
        </li>
      </ul>
    </Card.Body>
  </Card>
);

export default Appointments;
