export const MODELS_DATA = [
  {
    modelName: "Income 0/100",
    numberOfAccounts: "8",
    assets: "$1,252,931",
    ytd: "3.78",
    oneYr: "-0.70",
    threeYr: "-2.96",
    benchmark: "S&P Target Risk Conservative",
    up: "70",
    down: "49",
    upCapture: "32.07",
    downCapture: "34.12",
    painIndex: "2.19",
    aumByYear: [
      {
        year: "2015",
        amount: 8632631,
      },
      {
        year: "2016",
        amount: 10984452,
      },
      {
        year: "2017",
        amount: 11782566,
      },
      {
        year: "2018",
        amount: 15047930,
      },
      {
        year: "2019",
        amount: 17846281,
      },
      {
        year: "2020",
        amount: 17729171,
      },
      {
        year: "2021",
        amount: 15047930,
      },
      {
        year: "2022",
        amount: 17864192,
      },
      {
        year: "2023",
        amount: 20053724,
      },
    ],
  },
  {
    modelName: "Conservative 20/80",
    numberOfAccounts: "42",
    assets: "$39,862,202",
    ytd: "4.70",
    oneYr: "-0.01",
    threeYr: "0.08",
    benchmark: "S&P Target Risk Conservative",
    up: "80",
    down: "39",
    upCapture: "63.08",
    downCapture: "64.63",
    painIndex: "1.87",
    aumByYear: [
      {
        year: "2015",
        amount: 8632631,
      },
      {
        year: "2016",
        amount: 10984452,
      },
      {
        year: "2017",
        amount: 11782566,
      },
      {
        year: "2018",
        amount: 15047930,
      },
      {
        year: "2019",
        amount: 17846281,
      },
      {
        year: "2020",
        amount: 17729171,
      },
      {
        year: "2021",
        amount: 15047930,
      },
      {
        year: "2022",
        amount: 17864192,
      },
      {
        year: "2023",
        amount: 20053724,
      },
    ],
  },
  {
    modelName: "Mod Conserv 40/60",
    numberOfAccounts: "59",
    assets: "$55,486,284",
    ytd: "5.63",
    oneYr: "0.66",
    threeYr: "3.15",
    benchmark: "S&P Target Risk Moderate",
    up: "79",
    down: "40",
    upCapture: "95.67",
    downCapture: "93.41",
    painIndex: "2.19",
    aumByYear: [
      {
        year: "2015",
        amount: 8632631,
      },
      {
        year: "2016",
        amount: 10984452,
      },
      {
        year: "2017",
        amount: 11782566,
      },
      {
        year: "2018",
        amount: 15047930,
      },
      {
        year: "2019",
        amount: 17846281,
      },
      {
        year: "2020",
        amount: 17729171,
      },
      {
        year: "2021",
        amount: 15047930,
      },
      {
        year: "2022",
        amount: 17864192,
      },
      {
        year: "2023",
        amount: 20053724,
      },
    ],
  },
  {
    modelName: "Moderate 60/40",
    numberOfAccounts: "56",
    assets: "$52,277,325",
    ytd: "6.58",
    oneYr: "1.32",
    threeYr: "6.26",
    benchmark: "S&P Target Risk Moderate",
    up: "79",
    down: "40",
    upCapture: "130.05",
    downCapture: "120.52",
    painIndex: "2.72",
    aumByYear: [
      {
        year: "2015",
        amount: 8632631,
      },
      {
        year: "2016",
        amount: 10984452,
      },
      {
        year: "2017",
        amount: 11782566,
      },
      {
        year: "2018",
        amount: 15047930,
      },
      {
        year: "2019",
        amount: 17846281,
      },
      {
        year: "2020",
        amount: 17729171,
      },
      {
        year: "2021",
        amount: 15047930,
      },
      {
        year: "2022",
        amount: 17864192,
      },
      {
        year: "2023",
        amount: 20053724,
      },
    ],
  },
  {
    modelName: "Mod Aggressive 80/20",
    numberOfAccounts: "67",
    assets: "$35,478,598",
    ytd: "7.54",
    oneYr: "1.98",
    threeYr: "9.42",
    benchmark: "S&P Target Risk Growth",
    up: "80",
    down: "39",
    upCapture: "166.16",
    downCapture: "146.03",
    painIndex: "3.34",
    aumByYear: [
      {
        year: "2015",
        amount: 8632631,
      },
      {
        year: "2016",
        amount: 10984452,
      },
      {
        year: "2017",
        amount: 11782566,
      },
      {
        year: "2018",
        amount: 15047930,
      },
      {
        year: "2019",
        amount: 17846281,
      },
      {
        year: "2020",
        amount: 17729171,
      },
      {
        year: "2021",
        amount: 15047930,
      },
      {
        year: "2022",
        amount: 17864192,
      },
      {
        year: "2023",
        amount: 20053724,
      },
    ],
  },
  {
    modelName: "Aggressive 100/0",
    numberOfAccounts: "7",
    assets: "$1,858,197",
    ytd: "8.47",
    oneYr: "2.67",
    threeYr: "12.63",
    benchmark: "S&P Target Risk Aggressive",
    up: "80",
    down: "39",
    upCapture: "204.32",
    downCapture: "169.97",
    painIndex: "3.97",
    aumByYear: [
      {
        year: "2015",
        amount: 8632631,
      },
      {
        year: "2016",
        amount: 10984452,
      },
      {
        year: "2017",
        amount: 11782566,
      },
      {
        year: "2018",
        amount: 15047930,
      },
      {
        year: "2019",
        amount: 17846281,
      },
      {
        year: "2020",
        amount: 17729171,
      },
      {
        year: "2021",
        amount: 15047930,
      },
      {
        year: "2022",
        amount: 17864192,
      },
      {
        year: "2023",
        amount: 20053724,
      },
    ],
  },
];

export type ModelInfo = typeof MODELS_DATA[number];
export type ModelAUMYearInfo = typeof MODELS_DATA[number]["aumByYear"][number];
