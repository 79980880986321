import React from "react";

import { Card } from "react-bootstrap";
import { Opportunity } from "./types";

type OpportunitiesProps = {
  opps: Opportunity[];
};

export const Opportunities: React.FC<OpportunitiesProps> = ({ opps }) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title>Opportunities</Card.Title>
      </Card.Header>
      <Card.Body>
        {opps.map((opp) => (
          <>
            <div className="mb-3">
              <h6 className="mb-1">{opp.label}</h6>
              <div>
                <small>Target close: {opp.targetClose}</small>
              </div>
            </div>
            <hr className="my-0 mb-3" />
          </>
        ))}
      </Card.Body>
    </Card>
  );
};
