import React, { useState } from "react";
import Iframe from "react-iframe";

export const BlackDiamondClientDashboard: React.FC = () => {
  const blackDiamondUrl = process.env.REACT_APP_BLACK_DIAMOND_SSO_URL;
  const [url] = useState(
    blackDiamondUrl
      ? blackDiamondUrl +
          "?RelayState=https://bd3.bdreporting.com/clientview/relationship/z08104438/portfolio/dashboard"
      : ""
  );

  return (
    <React.Fragment>
      <Iframe
        url={url}
        id="blackDiamondClientDashboardFrame"
        width="100%"
        height="100%"
        display="initial"
        position="relative"
        className="iframe"
      />
    </React.Fragment>
  );
};
