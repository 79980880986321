import React from "react";
import { Container } from "react-bootstrap";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "qrvey-end-user": any;
    }
  }
}

const EmbeddedData = () => {
  const styles = `
            .css-grid{
                margin: 1px !important;
            }.piechart.spec-piechart-graph{
              height: calc(100% - 70px) !important;
              width: 100% !important;
            }
            an-panel, .qui-value-list-container{
                border-radius: 4px !important;
            }
            .fp-panel-drawer__one{
                background: #FFF !important;
            }`;

  const config = {
    api_key: "jKi1beHbEZCAPY00M3VqkPzcKkXAXjPbut710m5y",
    domain: "https://reports.advizorstack.com",
    dashboard_id: "AAWdq03Xv3CGMKY3ayqlV",
    app_id: "AjlMc3bRO",
    userFilters: {
      filters: [
        {
          operator: "AND",
          expressions: [
            {
              qrveyid: "ybct6ci6k",
              questionid: "MQIke4VZH",
              validationType: "EQUAL",
              value: ["Queen Ursula"],
            },
          ],
        },
      ],
    },
    custom_styles: true,
    customCSSRules: styles,
  };

  (window as any)["endUserConfig"] = config;

  return (
    <Container fluid className="p-0">
      <qrvey-end-user settings={"endUserConfig"} />
    </Container>
  );
};

export default EmbeddedData;
