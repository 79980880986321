import { useMemo } from "react";

const DATA: FinancialPlanClient[] = [
  {
    houseHoldName: "Tim Gitchel",
    planCreated: "8/15/2022",
    planUpdated: "2/2/2023",
    probabilityOfSuccess: "81%",
    openOpportunities: "N/A",
    amountOfOpportunity: "$32k",
  },
  {
    houseHoldName: "Brieene Tarth",
    planCreated: "3/14/2020",
    planUpdated: "5/15/2023",
    probabilityOfSuccess: "91%",
    openOpportunities: "N/A",
    amountOfOpportunity: "$2m",
  },
  {
    houseHoldName: "Dany Targaryen",
    planCreated: "6/17/2020",
    planUpdated: "12/3/2022",
    probabilityOfSuccess: "77%",
    openOpportunities: 2,
    amountOfOpportunity: "$27k",
  },
  {
    houseHoldName: "David Lee",
    planCreated: "7/30/2018",
    planUpdated: "5/27/2022",
    probabilityOfSuccess: "81%",
    openOpportunities: "N/A",
    amountOfOpportunity: "",
  },
  {
    houseHoldName: "Peter Lannister",
    planCreated: "7/31/2020",
    planUpdated: "2/3/2022",
    probabilityOfSuccess: "44%",
    openOpportunities: 1,
    amountOfOpportunity: "",
  },
  {
    houseHoldName: "Petyr Baelish",
    planCreated: "8/28/2017",
    planUpdated: "4/29/2022",
    probabilityOfSuccess: "67%",
    openOpportunities: "N/A",
    amountOfOpportunity: "",
  },
  {
    houseHoldName: "Queen Ursula",
    planCreated: "5/19/2019",
    planUpdated: "12/18/2022",
    probabilityOfSuccess: "4%",
    openOpportunities: 3,
    amountOfOpportunity: "",
  },
  {
    houseHoldName: "Robert Baratheon",
    planCreated: "10/10/2017",
    planUpdated: "6/8/2022",
    probabilityOfSuccess: "85%",
    openOpportunities: "N/A",
    amountOfOpportunity: "",
  },
  {
    houseHoldName: "Travis Porter",
    planCreated: "9/23/2018",
    planUpdated: "1/9/2023",
    probabilityOfSuccess: "93%",
    openOpportunities: "N/A",
    amountOfOpportunity: "",
  },
];

export type FinancialPlanClient = {
  houseHoldName: string;
  planCreated: string;
  planUpdated: string;
  probabilityOfSuccess: string;
  openOpportunities: number | string;
  amountOfOpportunity: string;
};

export const useClientData = () => {
  return useMemo(() => DATA, []);
};
