import React from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";
import loadingImg from "../../assets/img/loader.svg";

export const PageLoader: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet title="Loading..." />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Loading...</h1>

        <Row>
          <Col>
            <div className="align-middle w-25 h-25">
              <img src={loadingImg} alt="Loading..." />
            </div>{" "}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
