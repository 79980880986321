import { Table } from "react-bootstrap";

export const DrawdownOfReturn = () => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Drawdown of Return</th>
          <th>Maximum Drawdown Loss</th>
          <th>Maximum Drawdown Start Date</th>
          <th>Maximum Drawdown End Date</th>
          <th>Maximum Drawdown Length</th>
          <th>Maximum Drawdown Recovery Date</th>
          <th>High Water Mark Date</th>
          <th>High Water Mark</th>
          <th>Pain Index</th>
          <th>Pain Ratio</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Queen Ursula Portfolio</td>
          <td>-18.98%</td>
          <td>Dec-21</td>
          <td>Sep-22</td>
          <td>9</td>
          <td>N/A</td>
          <td>Dec-21</td>
          <td>9.19</td>
          <td>2.73</td>
          <td>2.42</td>
        </tr>
        <tr>
          <td>60/40 Model</td>
          <td>-19.39%</td>
          <td>Dec-21</td>
          <td>Sep-22</td>
          <td>9</td>
          <td>N/A</td>
          <td>Dec-21</td>
          <td>10.61</td>
          <td>2.47</td>
          <td>2.6</td>
        </tr>
      </tbody>
    </Table>
  );
};
