import { Col, Row, Table } from "react-bootstrap";

export const RiskAndMetricsTab = () => {
  return (
    <Row>
      <Col md={6}>
        <h3>Drawdown of Returns</h3>
        <div className="table-responsive">
          <Table>
            <thead>
              <tr>
                <td>MAXIMUM DRAWDOWN LOSS VALUE</td>
                <td>MAXIMUM DRAWDOWN START DATE</td>
                <td>MAXIMUM DRAWDOWN END DATE</td>
                <td>MAXIMUM DRAWDOWN LENGTH</td>
                <td>MAXIMUM DRAWDOWN RECOVERY DATE</td>
                <td>HIGH WATER MARK DATE</td>
                <td>HIGH WATER MARK</td>
                <td>PAIN INDEX</td>
                <td>PAIN RATIO</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>-20.67</td>
                <td>Dec 2021</td>
                <td>Sep 2022</td>
                <td>9</td>
                <td>N/A</td>
                <td>Dec 2021</td>
                <td>11.18</td>
                <td>2.72</td>
                <td>1.87</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
      <Col md={6}>
        <h3>Upside / Downside</h3>
        <div className="table-responsive">
          <Table>
            <thead>
              <tr>
                <td>UP</td>
                <td>DOWN</td>
                <td>UP</td>
                <td>DOWN</td>
                <td>BEST</td>
                <td>WORST</td>
                <td>BEST</td>
                <td>WORST</td>
                <td>UP CAPTURE</td>
                <td>DOWN CAPTURE</td>
                <td>R- SQUARED</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>79</td>
                <td>40</td>
                <td>1.96</td>
                <td>(2.33)</td>
                <td>7.48</td>
                <td>(8.94)</td>
                <td>31.81</td>
                <td>(17.70)</td>
                <td>130.05</td>
                <td>120.52</td>
                <td>97.45</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
};
