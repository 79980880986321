import { useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import { BarChart, BarChartProps } from "./BarChart";

const CALENDAR_DATA = [
  {
    Name: "Model",
    Data: [
      {
        year: "2013",
        amount: null,
      },
      {
        year: "2014",
        amount: 6.31,
      },
      {
        year: "2015",
        amount: -0.53,
      },
      {
        year: "2016",
        amount: 7.21,
      },
      {
        year: "2017",
        amount: 15.05,
      },
      {
        year: "2018",
        amount: -4.91,
      },
      {
        year: "2019",
        amount: 19.19,
      },
      {
        year: "2020",
        amount: 12.74,
      },
      {
        year: "2021",
        amount: 10.34,
      },
      {
        year: "2022",
        amount: -15.61,
      },
      {
        year: "YTD",
        amount: 6.58,
      },
    ],
  },
  {
    Name: "Benchmark",
    Data: [
      {
        year: "2013",
        amount: 10.47,
      },
      {
        year: "2014",
        amount: 4.49,
      },
      {
        year: "2015",
        amount: -1.06,
      },
      {
        year: "2016",
        amount: 5.77,
      },
      {
        year: "2017",
        amount: 11.78,
      },
      {
        year: "2018",
        amount: -3.72,
      },
      {
        year: "2019",
        amount: 15.71,
      },
      {
        year: "2020",
        amount: 10.42,
      },
      {
        year: "2021",
        amount: 7.12,
      },
      {
        year: "2022",
        amount: -14.41,
      },
      {
        year: "YTD",
        amount: 5.78,
      },
    ],
  },
];

const TRAILING_YEAR_RETURNS = [
  {
    Name: "Moderate 60/40",
    Data: [
      {
        year: "YTD",
        amount: 6.58,
      },
      {
        year: "1 YEAR",
        amount: 1.32,
      },
      {
        year: "3 YEARS",
        amount: 6.26,
      },
      {
        year: "5 YEARS",
        amount: 5.01,
      },
      {
        year: "7 YEARS",
        amount: 6.2,
      },
      {
        year: "10 YEARS",
        amount: null,
      },
      {
        year: "15 YEARS",
        amount: null,
      },
    ],
  },
  {
    Name: "Benchmark",
    Data: [
      {
        year: "YTD",
        amount: 5.78,
      },
      {
        year: "1 YEAR",
        amount: 1.23,
      },
      {
        year: "3 YEARS",
        amount: 3.82,
      },
      {
        year: "5 YEARS",
        amount: 3.81,
      },
      {
        year: "7 YEARS",
        amount: 4.61,
      },
      {
        year: "10 YEARS",
        amount: 4.36,
      },
      {
        year: "15 YEARS",
        amount: 4.09,
      },
    ],
  },
];

const convertDataToProps = (data: typeof CALENDAR_DATA): BarChartProps => {
  return {
    labels: data[0].Data.map((r) => r.year),
    datasets: data.map((r) => {
      return {
        label: r.Name,
        data: r.Data.map((x) => x.amount),
      };
    }),
  };
};

export const PeformanceTab = () => {
  const calendarYearReturnProps = useMemo((): BarChartProps => {
    return convertDataToProps(CALENDAR_DATA);
  }, []);

  const trailingYearReturnProps = useMemo((): BarChartProps => {
    return convertDataToProps(TRAILING_YEAR_RETURNS);
  }, []);

  return (
    <Row>
      <Col md={6}>
        <h3>Calendar Year Returns</h3>
        <BarChart {...calendarYearReturnProps} />
      </Col>
      <Col md={6}>
        <h3>Trailing Year Returns</h3>
        <BarChart {...trailingYearReturnProps} />
      </Col>
    </Row>
  );
};
