import React from "react";
import { Button, Card, Table } from "react-bootstrap";
import { PendingHouseholdRow } from "./PendingHouseholdRow";
import { PendingHouseholdState } from "./types";
import { ExternalRef, Household } from "../../../models/household";

type PendingLinksBodyProps = {
  data: Household[];
  saving: boolean;
  showSavedMessage: boolean;
  showSaveErrorMessage: boolean;
  onUpdate(household: ExternalRef, newState: PendingHouseholdState): void;
  onClickSave(): void;
  statuses: { [id: Household["id"]]: PendingHouseholdState };
};

export const PendingLinksBody: React.FC<PendingLinksBodyProps> = ({
  statuses,
  onUpdate,
  data,
  saving,
  onClickSave,
  showSavedMessage,
  showSaveErrorMessage,
}) => {
  if (data.length === 0) {
    return (
      <Card.Body>
        <em>No pending households at this time.</em>
      </Card.Body>
    );
  }

  return (
    <Card.Body>
      <Table>
        <thead>
          <tr>
            <th>Household Name</th>
            <th>Partner</th>
            <th>Partner Household Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => {
            return (
              row.externalRefs?.map((ref, refIndex) => (
                <PendingHouseholdRow
                  household={row}
                  state={statuses[ref.id] ?? "unselected"}
                  onUpdate={onUpdate}
                  key={row.id}
                  externalRef={ref}
                  externalRefIndex={refIndex}
                />
              )) ?? []
            );
          })}
        </tbody>
      </Table>
      <Button onClick={onClickSave} disabled={saving} variant="success">
        {saving ? "Saving..." : "Save"}
      </Button>
      {showSavedMessage && (
        <p className="mt-4 text-success">Saved household links successfully</p>
      )}
      {showSaveErrorMessage && (
        <p className="mt-4 text-danger">Unable to save household links</p>
      )}
    </Card.Body>
  );
};
