import { SidebarItemsType } from "../../types/sidebar";

import { Layout, Sliders, Share, User } from "react-feather";

const pagesSection = [
  {
    href: "/",
    icon: Sliders,
    title: "Home",
  },
  {
    href: "/pages",
    icon: Layout,
    title: "Business Intelligence",
    roleWhitelist: ["App Admin"],
    children: [
      // {
      //   title: "Black Diamond",
      //   badge: "New",
      //   children: [
      //     {
      //       href: "/pages/blackdiamond/menu",
      //       title: "Menu",
      //     },
      //     {
      //       href: "/pages/blackdiamond/securitymaster",
      //       title: "Security Master",
      //     },
      //     {
      //       href: "/pages/blackdiamond/accounts",
      //       title: "Accounts",
      //     },
      //     {
      //       href: "/pages/blackdiamond/portfolios",
      //       title: "Portfolios",
      //     },
      //     {
      //       href: "/pages/blackdiamond/clientdashboard",
      //       title: "Client Dashboard",
      //     },
      //   ],
      // },
      {
        href: "/pages/clients",
        title: "Clients",
      },
      // {
      //   href: "/pages/tasks",
      //   title: "Tasks",
      // },
      {
        href: "/pages/crm",
        title: "CRM Dashboard",
      },
      {
        href: "/pages/blank",
        title: "PSN / Manager Research",
      },
      {
        href: "/pages/blank",
        title: "Investment Vehicle Research",
      },
      {
        href: "/pages/models-trade-management",
        title: "Model & Trade Management",
      },
      {
        href: "financial-planning-dashboard",
        title: "Financial Planning Dashboard",
      },
      {
        href: "/pages/blank",
        title: "Billing Dashboard",
      },
      {
        href: "/pages/blank",
        title: "Performance Reporting",
      },
      {
        externalLink: "https://clients.advizorstack.com",
        title: "Client Portal Dashboard",
      },
      {
        href: "/pages/blank",
        title: "Compliance & Risk Oversight",
      },
    ],
  },
  {
    href: "/partners",
    icon: Share,
    title: "External Applications",
    children: [
      {
        href: "/partners/adhesion",
        title: "Trade Order Management",
        roleWhitelist: ["Adhesion User", "App Admin"],
      },
      {
        href: "/partners/blackdiamond",
        title: "Billing & Reporting",
        roleWhitelist: ["Black Diamond User", "App Admin"],
      },
      {
        href: "/partners/elements",
        title: "Customer Relationship Management",
        roleWhitelist: ["Black Diamond User", "App Admin"],
      },
      {
        href: "/partners/moneyguidepro",
        title: "Financial Planning",
        roleWhitelist: ["MoneyGuide Pro User", "App Admin"],
      },
    ],
  },
  {
    href: "/users",
    icon: User,
    title: "User",
    roleWhitelist: ["App Admin"],
    children: [
      {
        href: "/pages/profile",
        title: "Profile",
      },
      {
        href: "/pages/settings",
        title: "Settings",
      },
      {
        href: "/pages/firms",
        title: "Roles",
        roleWhitelist: ["App Admin"],
      },
    ],
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "Main",
    pages: pagesSection,
  },
];

export default navItems;
