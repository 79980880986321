// import clientData from "./client-data.json";
import { CRMClient } from "./types";

const CLIENT_DATA: CRMClient[] = [
  {
    houseHoldName: "Tim Gitchel",
    clientName: "Tim Gitchel",
    household: "Head",
    contactType: "Client",
    openTasks: true,
    lastCRMActivity: "5/1/2023",
    houseHoldName_1: "9876 Rodeo Ln",
    name: "Dallas",
    address: "TX",
    city: 75001,
    state: "tim@advizorstack.com",
    zip: "(399) 365-8282",
    eMail: "Cell",
    phone: "Married",
    phoneType: "AdvizorStack",
    maritalStatus: "CTO",
    company: "Head",
    jobTitle: "",
    household_1: "Client",
    nickName: "Gold Client",
    contactType_1: "College 529 Plan",
    tag_1: "Financial Planning A",
    tag_2: "5/1/2023",
    tag_3: "3/13/2017",
  },
  {
    houseHoldName: "Brieene Tarth",
    clientName: "Brieene Tarth",
    household: "Head",
    contactType: "Past Client",
    openTasks: true,
    lastCRMActivity: "4/18/2023",
    houseHoldName_1: "6623 N Hollywood Blvd",
    name: "Beverly Hills",
    address: "CA",
    city: 90210,
    state: "BrienneT@Tarth.com",
    zip: "(850) 835-4388",
    eMail: "Home",
    phone: "Married",
    phoneType: "Self-Employed",
    maritalStatus: "Knight",
    company: "Head",
    jobTitle: "",
    household_1: "Past Client",
    nickName: "AA List Client",
    contactType_1: "Insurance",
    tag_1: "",
    tag_2: "4/18/2023",
    tag_3: "11/30/2018",
  },
  {
    houseHoldName: "Dany Targaryen",
    clientName: "Dany Targaryen",
    household: "Head",
    contactType: "Client",
    openTasks: true,
    lastCRMActivity: "4/22/2023",
    houseHoldName_1: "7744 S Main St",
    name: "Manhattan",
    address: "KS",
    city: 66502,
    state: "DanyT@Targaryen.com",
    zip: "(349) 477-7682",
    eMail: "Work",
    phone: "Divorced",
    phoneType: "Self-Employed",
    maritalStatus: "Dragon Wrangler",
    company: "Head",
    jobTitle: "",
    household_1: "Client",
    nickName: "A List Client",
    contactType_1: "Golf",
    tag_1: "401k",
    tag_2: "4/22/2023",
    tag_3: "12/31/2013",
  },
  {
    houseHoldName: "David Lee",
    clientName: "David Lee",
    household: "Head",
    contactType: "Client",
    openTasks: true,
    lastCRMActivity: "3/31/2023",
    houseHoldName_1: "403 Annadale Ln",
    name: "Lagrange",
    address: "GA",
    city: 30240,
    state: "tim@advizorstack.com",
    zip: "(976) 536-2035",
    eMail: "Cell",
    phone: "Unknown",
    phoneType: "NBA",
    maritalStatus: "Power Forward",
    company: "Head",
    jobTitle: "",
    household_1: "Client",
    nickName: "",
    contactType_1: "",
    tag_1: "",
    tag_2: "3/31/2023",
    tag_3: "12/29/2022",
  },
  {
    houseHoldName: "Peter Lannister",
    clientName: "Peter Lannister",
    household: "Head",
    contactType: "Client",
    openTasks: true,
    lastCRMActivity: "3/28/2023",
    houseHoldName_1: "4325 Snow Leopard Ln",
    name: "Cheyenne",
    address: "WY",
    city: 82001,
    state: "PeterL@Lannister.com",
    zip: "(671) 554-1840",
    eMail: "Cell",
    phone: "Life Partner",
    phoneType: "Government",
    maritalStatus: "Consultant",
    company: "Head",
    jobTitle: "",
    household_1: "Client",
    nickName: "",
    contactType_1: "",
    tag_1: "",
    tag_2: "3/28/2023",
    tag_3: "12/30/2021",
  },
  {
    houseHoldName: "Petyr Baelish",
    clientName: "Petyr Baelish",
    household: "Head",
    contactType: "Client",
    openTasks: true,
    lastCRMActivity: "5/1/2023",
    houseHoldName_1: "55123 Cowboy Trail",
    name: "Austin",
    address: "TX",
    city: 73301,
    state: "PetyrB@Baelish.com",
    zip: "(233) 414-9367",
    eMail: "Home",
    phone: "Widowed",
    phoneType: "Catapult",
    maritalStatus: "Rock Loader",
    company: "Head",
    jobTitle: "",
    household_1: "Client",
    nickName: "A List Client",
    contactType_1: "",
    tag_1: "",
    tag_2: "5/1/2023",
    tag_3: "12/31/2015",
  },
  {
    houseHoldName: "Queen Ursula",
    clientName: "Queen Ursula",
    household: "Head",
    contactType: "Client",
    openTasks: true,
    lastCRMActivity: "4/14/2023",
    houseHoldName_1: "1234 Sea Floor Dr",
    name: "Hermosa Beach",
    address: "CA",
    city: 90254,
    state: "ursula@ursula.com",
    zip: "(944) 827-0699",
    eMail: "Fax",
    phone: "Single",
    phoneType: "Walt Disney Corporation",
    maritalStatus: "Underwater Enchantress",
    company: "Head",
    jobTitle: "",
    household_1: "Client",
    nickName: "Gold Client",
    contactType_1: "",
    tag_1: "",
    tag_2: "4/14/2023",
    tag_3: "11/1/2013",
  },
  {
    houseHoldName: "Robert Baratheon",
    clientName: "Robert Baratheon",
    household: "Head",
    contactType: "Client",
    openTasks: true,
    lastCRMActivity: "4/7/2023",
    houseHoldName_1: "4444 N Highlands Ave",
    name: "Rocky Mountain National Park",
    address: "CO",
    city: 80517,
    state: "RobertB@Baratheon.com",
    zip: "(526) 770-8610",
    eMail: "Cell",
    phone: "Single",
    phoneType: "Government",
    maritalStatus: "King",
    company: "Head",
    jobTitle: "",
    household_1: "Client",
    nickName: "",
    contactType_1: "",
    tag_1: "",
    tag_2: "4/7/2023",
    tag_3: "1/2/2018",
  },
  {
    houseHoldName: "Travis Porter",
    clientName: "Travis Porter",
    household: "Head",
    contactType: "Client",
    openTasks: true,
    lastCRMActivity: "5/5/2023",
    houseHoldName_1: "5678 Olsen Dr",
    name: "Durham",
    address: "NC",
    city: 27708,
    state: "TravisP@Porter.com",
    zip: "(892) 995-0749",
    eMail: "Cell",
    phone: "Seperated",
    phoneType: "Self-Employed",
    maritalStatus: "Musician",
    company: "Head",
    jobTitle: "",
    household_1: "Client",
    nickName: "",
    contactType_1: "",
    tag_1: "",
    tag_2: "5/5/2023",
    tag_3: "8/29/2014",
  },
  {
    houseHoldName: "Queen Ursula",
    clientName: "Jetsam Ursula",
    household: "Child",
    contactType: "Prospect",
    openTasks: false,
    lastCRMActivity: "2/19/2023",
    houseHoldName_1: "1234 Sea Floor Dr",
    name: "Hermosa Beach",
    address: "CA",
    city: 90254,
    state: "Jetsam@ursula.com",
    zip: "(546) 459-6426",
    eMail: "Other",
    phone: "Single",
    phoneType: "Walt Disney Corporation",
    maritalStatus: "Assistant Henchman",
    company: "Child",
    jobTitle: "Jetty",
    household_1: "Prospect",
    nickName: "Prospect",
    contactType_1: "",
    tag_1: "",
    tag_2: "2/19/2023",
    tag_3: "",
  },
  {
    houseHoldName: "Queen Ursula",
    clientName: "Flotsam Ursula",
    household: "Child",
    contactType: "Client",
    openTasks: true,
    lastCRMActivity: "12/13/2023",
    houseHoldName_1: "1234 Sea Floor Dr",
    name: "Hermosa Beach",
    address: "CA",
    city: 90254,
    state: "Flotsam@ursula.com",
    zip: "(627) 613-9233",
    eMail: "Vacation",
    phone: "Single",
    phoneType: "Walt Disney Corporation",
    maritalStatus: "Assistant Henchman",
    company: "Child",
    jobTitle: "Sam",
    household_1: "Client",
    nickName: "Gold Client",
    contactType_1: "Insurance",
    tag_1: "Financial Planning C",
    tag_2: "12/13/2023",
    tag_3: "11/1/2013",
  },
];

export const useClientData = () => {
  return CLIENT_DATA;
};
