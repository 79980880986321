import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { PieChart, Settings, User } from "react-feather";

const NavbarUser = () => {
  const { logout, user } = useAuth0();
  const onClickLogout = React.useCallback(() => {
    logout();
  }, [logout]);

  const userName = React.useMemo(() => {
    if (user?.name) {
      return user?.name;
    }

    return [user?.given_name, user?.family_name].filter(Boolean).join(" ");
  }, [user]);

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          {user?.picture && (
            <img
              src={user?.picture}
              className="avatar img-fluid rounded-circle me-1"
              alt={userName}
            />
          )}
          <span className="text-dark">{userName}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu>
        <Dropdown.Item>
          <User size={18} className="align-middle me-2" />
          Profile
        </Dropdown.Item>
        <Dropdown.Item>
          <PieChart size={18} className="align-middle me-2" />
          Analytics
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item>Settings & Privacy</Dropdown.Item>
        <Dropdown.Item>Help</Dropdown.Item>
        <Dropdown.Item onClick={onClickLogout}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
