import { useMemo } from "react";
import { useApiEffect } from "../../../services/api";
import { ExternalRef, Household } from "../../../models/household";

const groupExternalRefByPartner = (refs: ExternalRef[]) => {
  return refs.reduce((accum, externalRef) => {
    if (!accum[externalRef.externalOrganization?.name!]) {
      accum[externalRef.externalOrganization?.name!] = [];
    }

    accum[externalRef.externalOrganization?.name!].push(externalRef);

    return accum;
  }, {} as { [name: string]: ExternalRef[] });
};

export const useHousehold = (index: number) => {
  const { data } = useApiEffect<Household[]>(
    `Households?includeExternalRefs=true`
  );

  if (data && data[index]) {
    const household = data[index];
    return {
      household,
      groupedRefs: groupExternalRefByPartner(household.externalRefs!),
    };
  }

  return {
    household: null,
    groupedRefs: null,
  };
};

export const useExternalRefs = (refType: string, syncType: string) => {
  const params = useMemo(() => {
    return new URLSearchParams({
      refType,
      syncType,
    });
  }, [refType, syncType]);

  const { loading, data } = useApiEffect<ExternalRef[]>(
    `ExternalRefs?${params.toString()}`
  );
  const refsByPartner = useMemo(() => {
    if (!data) {
      return null;
    }

    return groupExternalRefByPartner(data);
  }, [data]);

  return { loading, data: refsByPartner };
};
