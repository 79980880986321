import { Button, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

export const TeamList: React.FC<{}> = () => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">Teams</Card.Title>
    </Card.Header>
    <Card.Body>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Members</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="align-top">Steve Ankerstar</td>
            <td>
              <p>David Chisum</p>
              <p>Joel Hanson</p>
              <p>Jaden Verrico</p>
            </td>
            <td className="align-top">
              <Link to="/pages/firms/firm-id" className="btn btn-primary me-2">
                Manage
              </Link>
              <Button>Rep Codes</Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </Card.Body>
  </Card>
);
