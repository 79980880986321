import React, { useState } from "react";
import Iframe from "react-iframe";

export const MoneyGuidePro: React.FC = () => {
  const moneyGuideProUrl = process.env.REACT_APP_MGP_SSO_URL;
  const [url] = useState(moneyGuideProUrl ? moneyGuideProUrl : "");

  return (
    <React.Fragment>
      <Iframe
        url={url}
        id="moneyGuideProFrame"
        width="100%"
        height="100%"
        display="initial"
        position="relative"
        className="iframe"
      />
    </React.Fragment>
  );
};
