import React from "react";
import clientData from "./client-data.json";
import { Client } from "./types";

const parseBool = (value: string): boolean => value === "Yes";
const parseClient = (rawClient: typeof clientData[number]): Client => {
  const {
    portfolioRiskGreaterThanRtq,
    accountsOutOfTolerance,
    openOppourtunities,
    openTasks,
    clientSince,
    ...rest
  } = rawClient;

  return {
    ...rest,
    openTasks: parseBool(openTasks),
    openOppourtunities: parseBool(openOppourtunities),
    accountsOutOfTolerance: parseBool(accountsOutOfTolerance),
    portfolioRiskGreaterThanRtq: parseBool(portfolioRiskGreaterThanRtq),
    clientSince: clientSince ? new Date(clientSince) : undefined,
  };
};

export const useClientData = () => {
  // TODO: fetch real data
  const parsedData = React.useMemo(() => {
    return clientData.map(parseClient) as Client[];
  }, []);

  return parsedData;
};

export const useSingleClient = (index: number) => {
  // TODO: fetch real data
  const parsedData = React.useMemo(() => {
    return clientData.map(parseClient)[index] as Client;
  }, [index]);

  return parsedData;
};
