import { Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SelectableTableRow } from "../../../components/SelectableTableRow";
import { ModelInfo } from "./data";

type TopModelsProps = {
  models: ModelInfo[];
  selectedModel: ModelInfo;
  onSelectModel(model: ModelInfo): void;
};

export const TopModels: React.FC<TopModelsProps> = ({
  onSelectModel,
  selectedModel,
  models,
}) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title>Top Models</Card.Title>
      </Card.Header>
      <Card.Body>
        <Table>
          <thead>
            <tr>
              <td>Model Name</td>
              <td># of Accounts</td>
              <td>Assets</td>
              <td>YTD %</td>
              <td>1 Yr %</td>
              <td>3 yr %</td>
              <td>Benchmark</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {models.map((m, index) => (
              <SelectableTableRow
                isSelected={selectedModel === m}
                onClick={() => onSelectModel(m)}
                key={m.modelName}
              >
                <td>{m.modelName}</td>
                <td>{m.numberOfAccounts}</td>
                <td>{m.assets}</td>
                <td>{m.ytd}</td>
                <td>{m.oneYr}</td>
                <td>{m.threeYr}</td>
                <td>{m.benchmark}</td>
                <td>
                  <Link
                    to={`/pages/models-trade-management/${index}/holdings`}
                    className="btn btn-primary"
                  >
                    Details
                  </Link>
                </td>
              </SelectableTableRow>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
