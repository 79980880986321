import React from "react";

import { Card } from "react-bootstrap";
import { useClientData } from "../clients/hooks";
import { useParams } from "react-router-dom";
import { ExternalRef, Household } from "../../../models/household";
import { LinkedRefSection } from "./LinkedRefSection";
import { SaveExternalRefsFunction } from "../../../models/externalRef";
import { UnlinkedRefSection } from "./UnlinkedRefSection";

type PartnerAppSectionProps = {
  name: string;
  allRefs: ExternalRef[] | null;
  currentRefs: ExternalRef[] | null;
  saveExternalRef: SaveExternalRefsFunction;
  household: Household;
};

export const PartnerAppSection: React.FC<PartnerAppSectionProps> = ({
  name,
  allRefs,
  currentRefs,
  saveExternalRef,
  household,
}) => {
  const { householdId } = useParams<{ householdId: string }>();
  const clients = useClientData();
  const client = clients[Number(householdId)];

  if (!currentRefs || !currentRefs.length) {
    return (
      <Card>
        <Card.Header>
          <Card.Title>{name}</Card.Title>
          <p className="text-danger mb-0">No linked household</p>
        </Card.Header>
        <Card.Body>
          <UnlinkedRefSection
            household={household}
            allRefs={allRefs}
            name={name}
            client={client}
            saveExternalRef={saveExternalRef}
          />
        </Card.Body>
      </Card>
    );
  } else if (currentRefs?.length) {
    return (
      <Card>
        <Card.Header>
          <Card.Title>{name}</Card.Title>
          {currentRefs.some((r) => r.syncStatus === "Pending") ? (
            <p className="text-warning mb-0">Pending Link with {name}</p>
          ) : (
            <p className="text-success mb-0">Linked with {name}</p>
          )}
        </Card.Header>
        <Card.Body>
          {currentRefs.map((linkedRef) => (
            <LinkedRefSection
              key={linkedRef.id}
              client={client}
              name={name}
              linkedRef={linkedRef}
              saveExternalRef={saveExternalRef}
            />
          ))}
        </Card.Body>
      </Card>
    );
  } else {
    return (
      <Card>
        <Card.Header>
          <Card.Title>{name}</Card.Title>
          <p className="text-warning mb-0">Pending link</p>
        </Card.Header>
        <Card.Body></Card.Body>
      </Card>
    );
  }
};
