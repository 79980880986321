import { Col, Row, Table } from "react-bootstrap";

const HOLDING_DATA = [
  {
    Name: "Vanguard S&P 500 ETF",
    Ticker: "VOO",
    Allocation: "30.20%",
  },
  {
    Name: "Vanguard Extended Market ETF",
    Ticker: "VXF",
    Allocation: "5.10%",
  },
  {
    Name: "Vanguard FTSE Developed Markets ETF",
    Ticker: "VEA",
    Allocation: "17.70%",
  },
  {
    Name: "Vanguard Emerging Markets ETF",
    Ticker: "VWO",
    Allocation: "5.80%",
  },
  {
    Name: "Vanguard Short-Term Bond ETF",
    Ticker: "BSV",
    Allocation: "10.80%",
  },
  {
    Name: "Vanguard Intermediate Term ETF",
    Ticker: "BIV",
    Allocation: "5.50%",
  },
  {
    Name: "Vanguard Long-Term Bond ETF",
    Ticker: "BLV",
    Allocation: "5.50%",
  },
  {
    Name: "Vanguard Mortgage-Backed Securities ETF",
    Ticker: "VMBS",
    Allocation: "5.60%",
  },
  {
    Name: "Vanguard Total International Bond ETF",
    Ticker: "BNDX",
    Allocation: "11.80%",
  },
  {
    Name: "",
    Ticker: "Cash",
    Allocation: "2.00%",
  },
];

const CLIENT_DATA = [
  {
    "Household Name": "Petyr Baelish",
    "Client Name": "Baelish, Petry - Individual",
    "Account #": "900000018",
    "$ in model": "1,020.52",
  },
  {
    "Household Name": "Petyr Baelish",
    "Client Name": "Baelish, Petyr - 401k",
    "Account #": "900000017",
    "$ in model": "1,024.62",
  },
  {
    "Household Name": "Petyr Baelish",
    "Client Name": "Baelish, Petyr - IRA",
    "Account #": "900000005",
    "$ in model": "67,265.05",
  },
  {
    "Household Name": "Peter Lannister",
    "Client Name": "Lannister, Peter - Individual",
    "Account #": "900000100",
    "$ in model": "4,139,700.00",
  },
  {
    "Household Name": "David Lee",
    "Client Name": "Lee, David - Trust",
    "Account #": "5678DEMO",
    "$ in model": "6,389,736.92",
  },
  {
    "Household Name": "Queen Ursula",
    "Client Name": "Ursula Trad IRA",
    "Account #": "23456788",
    "$ in model": "5,090.37",
  },
  {
    "Household Name": "Queen Ursula",
    "Client Name": "Ursula Under The Sea Trust",
    "Account #": "23456789",
    "$ in model": "9,449,886.54",
  },
];

export const HoldingDetailsTab: React.FC<{}> = () => {
  return (
    <Row className="gx-5">
      <Col md={4}>
        <h3>Holdings</h3>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Ticker</th>
              <th>Allocation</th>
            </tr>
          </thead>
          <tbody>
            {HOLDING_DATA.map((holding) => (
              <tr>
                <td>{holding.Name}</td>
                <td>{holding.Ticker}</td>
                <td>{holding.Allocation}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>

      <Col md={4}>
        <h3>Clients using</h3>
        <Table>
          <thead>
            <tr>
              <th>Household Name</th>
              <th>Client Name</th>
              <th>Account #</th>
              <th>$ in model</th>
            </tr>
          </thead>
          <tbody>
            {CLIENT_DATA.map((client) => (
              <tr>
                <td>{client["Household Name"]}</td>
                <td>{client["Client Name"]}</td>
                <td>{client["Account #"]}</td>
                <td>{client["$ in model"]}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>

      <Col md={4}>
        <h3>Equity Characteristics</h3>
        <Table>
          <tbody>
            <tr>
              <td>&quot;Market Capitalization&quot;</td>
              <td>109501.48</td>
            </tr>
            <tr>
              <td>Price/Earnings Ratio</td>
              <td>16.87</td>
            </tr>
            <tr>
              <td>Price/Book Ratio</td>
              <td>2.78</td>
            </tr>
            <tr>
              <td>Price/Sales Ratio</td>
              <td>1.82</td>
            </tr>
            <tr>
              <td>Price/Cash Flow Ratio</td>
              <td>12.37</td>
            </tr>
            <tr>
              <td>Turnover Ratio</td>
              <td>3.81</td>
            </tr>
          </tbody>
        </Table>
        <h3 className="mt-4">Equity Characteristics</h3>
        <Table>
          <tbody>
            <tr>
              <td>Effective Maturity</td>
              <td></td>
              <td>9.07</td>
            </tr>
            <tr>
              <td>Effective Duration</td>
              <td></td>
              <td>6.9</td>
            </tr>
            <tr>
              <td>Average Coupon</td>
              <td></td>
              <td>2.75</td>
            </tr>
            <tr>
              <td>Average Credit Quality</td>
              <td></td>
              <td>5.92</td>
            </tr>
            <tr>
              <td>Average Price</td>
              <td></td>
              <td>92.09</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};
