import { Card, Col, Row, Table } from "react-bootstrap";

import avatar3 from "../../../assets/img/avatars/avatar-3.jpg";
import { FinancialPlanClient } from "./hooks";

type ClientDetailProps = {
  client: FinancialPlanClient;
};

export const ClientDetail: React.FC<ClientDetailProps> = ({ client }) => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">{client.houseHoldName}</Card.Title>
    </Card.Header>
    <Card.Body>
      <Row className="g-0">
        <Col sm="3" xl="12" className="col-xxl-3 text-center">
          <img
            src={avatar3}
            width="64"
            height="64"
            className="rounded-circle mt-2"
            alt={client.houseHoldName}
          />
        </Col>
        <Col sm="9" xl="12" className="col-xxl-9">
          <strong>Notes</strong>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </Col>
      </Row>

      <Table size="sm" className="my-2">
        <tbody>
          <tr>
            <th>Plan Created</th>
            <td>{client.planCreated}</td>
          </tr>
          <tr>
            <th>Plan Updated</th>
            <td>{client.planUpdated}</td>
          </tr>
          <tr>
            <th>Amount of Opportunity</th>
            <td>{client.amountOfOpportunity}</td>
          </tr>
        </tbody>
      </Table>
      <Row className="mt-4 text-center">
        <Col xs={4}>
          <h3 className="mb-2">10</h3>
          <small className="mb-2">Portfolio Risk Score</small>
        </Col>
        <Col xs={4}>
          <h3 className="mb-2">42</h3>
          <small className="mb-2">Risk Score</small>
        </Col>
        <Col xs={4}>
          <h3 className="mb-2">54%</h3>
          <small className="mb-2">Probability of Success</small>
        </Col>
      </Row>

      <hr />

      <strong>Activity</strong>

      <ul className="timeline mt-2">
        <li className="timeline-item">
          <strong>Call</strong>
          <span className="float-end text-muted text-sm">2023-04-02 2P</span>
          <p>Call for client to perform a distribution from IRA Account</p>
        </li>
        <li className="timeline-item">
          <strong>Email</strong>
          <span className="float-end text-muted text-sm">2023-03-29 9A</span>
          <p>Email to touch base on clients investments</p>
        </li>
        <li className="timeline-item">
          <strong>Email</strong>
          <span className="float-end text-muted text-sm">2023-03-18 10A</span>
          <p>
            Sent a follow-up email to client regarding the distribution from
            their IRA account
          </p>
        </li>
        <li className="timeline-item">
          <strong>Task</strong>
          <span className="float-end text-muted text-sm">2023-02-09 8A</span>
          <p>
            Made a note in the CRM system to schedule a call with the client on
            the following day.
          </p>
        </li>
        <li className="timeline-item">
          <strong>Call</strong>
          <span className="float-end text-muted text-sm">2023-01-27 11A</span>
          <p>
            Contacted the client to discuss the status of the distribution
            request.
          </p>
        </li>
      </ul>
    </Card.Body>
  </Card>
);
