import React from "react";
import { Col, Row } from "react-bootstrap";

const CONTENT = [
  {
    heading: "Client Account Aggregation",
    desc: [
      "Aggregation of outside accounts and a customizable client portal that is built and whitebranded with your firm’s logo.  Including access to 3,000 direct custodial feeds. ",
    ],
  },
  {
    heading: "Billing Automation",
    desc: [
      "Assign fee schedules to the individual account level with customized billing configuration to meet any firm or advisors fee schedule. ",
    ],
  },
  {
    heading: "White Glove Service",
    desc: [
      "We assist you in building your billing configuration and fee schedule. Templates can be run automatically and then exported for any custodial upload.",
    ],
  },
  {
    heading: "Client View",
    desc: [
      "A dynamic, customizable view of your clients household that shows all account aggregation transactions, history, performance, reports, etc. ",
    ],
  },
  {
    heading: "Business Intelligence",
    desc: [
      "Tableau style BI interface that can derive any type of firm or user KPIs that are reconciled daily. Customizable interface and data queries can derive from any fields located within the system ",
    ],
  },
  {
    heading: "Centralization",
    desc: [
      "A centralized hub for your business with an in-depth look at truly how healthy your book of business is",
    ],
  },
  {
    heading: "Custodian Agnostic",
    desc: [
      `Access to over 3,000 custodians and financial institutions to be able to show your clients a comprehensive picture of their wealth and provide you with important oversight of your client’s financial well-being. `,
    ],
  },
  {
    heading: "Integrations",
    desc: [
      "Outside integrations with our vendors to bring over information to the end user and client ",
    ],
  },
  {
    heading: "State-of-the-art client portal",
    desc: [
      `Outside client account aggregation.`,
      `Timeline and Vault to digitally and securely message clients.`,
      `Perform client reviews fully digitally by using their client portal experience.`,
    ],
  },
];

export const BlackDiamondLink: React.FC = () => {
  return (
    <React.Fragment>
      <div className="d-grid">
        <a
          href={process.env.REACT_APP_BLACK_DIAMOND_SSO_URL}
          className="btn btn-primary vw-50 mx-auto"
          target="_blank"
          rel="noreferrer noopener"
        >
          Launch BlackDiamond
        </a>
      </div>
      <Row className="mt-5">
        {CONTENT.map((section) => (
          <Col className="text-center" md={4}>
            <h2>{section.heading}</h2>
            {section.desc.map((p) => (
              <p>{p}</p>
            ))}
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};
